/**=====================
     63. Responsive CSS Start
  ==========================**/

@media only screen and (max-width: 1600px) {
  .about {
    &.app1 {
      .mobile {
        right: 6%;
        &.mobile-img {
          .mobile-screen {
            height: 750px;
          }
        }
      }
    }
  }
  .agency {
    .girl-boy {
      right: 8%;
    }
  }
  .header {
    .wedding-content {
      .girl {
        left: 16%;
        img {
          width: 500px;
        }
      }
    }
  }
  .sponsor {
    div {
      .sponsor-img {
        .overlay-box {
          width: 100%;
        }
      }
    }
  }
  .header {
    .header8-content {
      .slider-1 {
        .owl-nav {
          left: -15%;
          bottom: -34%;
          transform: translate(18%, 17%);
          .owl-next {
            transform: translate(50%, 30%);
          }
        }
      }
    }
    .saas2-content {
      .set-abs {
        .img-dextop {
          height: 800px;
        }
      }
    }
  }
  .saas2 {
    .set-center-div {
      min-height: 525px;
      .side-img {
        right: -14%;
        img {
          height: 525px;
        }
      }
    }
  }
}

@media(min-width: 1470px) {
  .event, .gym, .agency, .music, .resume, .yoga, .wedding {
    .container {
      max-width: 1440px;
    }
  }
  .menu-set{
    right: unset!important;
  }
}

@media(max-width: 1469px) {
  .music{
    div{
      .album-list{
        padding: 30px;
      }
    }
  }
  header {
    &.wedding {
      .logo-abs {
        width: 25%;
      }
    }
  }
}

@media (max-width: 1430px) {
  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 38px;
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 175px;
          .qty-box {
            .input-group {
              .form-control {
                width: 75px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 41px;
        }
      }
    }
  }
  .portfolio-metro{
    h1{
      font-size: 70px;
    }
  }

  // inner pages //
  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width:100%;
          border-left:1px solid $round-border;
          border-bottom: 1px solid $round-border;
          text-align: center;
        }
        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left:1px solid $round-border;
        }
        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;
          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }
        .product-page-filter {
          border-right: 1px solid $round-border;
          width: 60%;
        }
        .product-page-per-view {
          width: 30%;
        }
      }
      .popup-filter {
        .collection-view {
          width: 10%;
        }
        .product-page-per-view {
          width: 25%;
        }
      }
    }
  }
  .cart-section, .wishlist-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 38px;
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 175px;
          .qty-box {
            .input-group {
              .form-control {
                width: 75px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 41px;
        }
      }
    }
  }
  .product-right {
    .product-icon {
      .product-social {
        li {
          padding-right: 10px;
        }
      }
    }
  }
  .product-form-box {
    .timer {
      padding-left: 17px;
      span {
        width: 55px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1200px) {

  .agency.blog {
    .blog-agency {
      .blog-contain {
        .img-container{
          .blog-head{
            margin-bottom: 10px;
          }
          .blog-info{
            .m-b-20{
              margin-bottom: 10px !important;
            }
          }
        }
      }
    }
  }
  footer {
    &.footer2 {
      &.saas2 {
        .footer-padding {
          padding-top: 100px;
        }
      }
    }
  }
  .brand-slider {
    .saas-brand {
      img {
        padding: 0;
      }
    }
  }
  .saas1 {
    &.build-bg {
      .container {
        margin-bottom: 1%;
        margin-top: 1%;
      }
    }
  }
  .p-b-160{
    padding-bottom: 130px;
  }
}

@media only screen and (max-width: 1366px) {
  .saas1 {
    .sync2 {
      .owl-item {
        .item {
          img {
            opacity: 0;
          }
        }
        &.active {
          .item {
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    &.build-bg {
      min-height: unset;
      .build-right-slider {
        .work-slide {
          height: unset;
        }
      }
    }
  }

  .gym, .yoga {
    .portfolio-creative {
      .head-sub-text {
        padding: 0 50px;
        letter-spacing: 0;
      }
    }
    .trainers-slider {
      &.owl-carousel {
        .owl-stage-outer {
          min-height: 375px !important
        }
      }
    }
  }
  .yoga {
    &.testimonial {
      .testimonial-info {
        margin-top: 30px;
      }
    }
    &.event {
      .event-container {
        margin-bottom: 50px;
        .event-info {
          .address, .time {
            margin-bottom: 5px;
            line-height: 1.8;
          }
        }
      }
    }
  }
  //inner pages start
  .section-404 {
    .box_astronaut {
      top: 65%;
      right: 15%;
    }
    .object_astronaut {
      width: 130px;
    }
    .text-inner {
      margin-top: -48px;
      margin-bottom: -45px;
    }
    .object_rocket {
      width: 110px;
    }
    .object_earth {
      width: 80px;
    }
    .object_moon {
      width: 60px;
    }
  }
  //inner pages end

  //menu css end
  section, footer {
    padding: 70px 0;
  }
  .p-t-100 {
    padding-top: 70px !important;
  }
  .p-b-100 {
    padding-bottom: 70px !important;
  }
  .header {
    .rotate {
      top: 14%;
      right: 15%;
      img {
        width: 400px;
      }
    }
    .squares {
      img {
        height: 260px;
      }
    }
    .wedding-content {
      .girl {
        left: 11%;
      }
    }
    .header8-content {
      .slider-1 {
        .owl-nav {
          left: -18%;
          bottom: -50%;
        }
      }
    }
    .saas2-content {
      .set-abs {
        .img-dextop {
          height: 720px;
        }
      }
    }
    &.app1 {
      h1 {
        line-height: 56px;
      }
    }
    .event-content {
      .counter-container {
        h2 {
          margin-top: -10px;
        }
      }
    }
    &.saas2 {
      h1 {
        margin-top: -14px;
      }
    }
    &.agency {
      .girl-boy {
        right: 10%;
        img {
          width: 440px;
        }
      }
    }
    &.agency {
      .center-text {
        margin-left: 50px;
      }
    }
  }
  .about {
    &.app1 {
      .counters {
        .abouts {
          padding: 60px 0;
        }
      }
      .mobile {
        right: 5%;
        &.mobile-img {
          .mobile-screen {
            height: 680px;
          }
        }
        &.rectangle {
          top: -5%;
          right: -4%;
          &.r-2 {
            right: -12%;
            top: -5%;
          }
        }
        &.galaxy {
          right: -12%;
          img {
            height: 120px;
          }
        }
      }
    }
  }
  .event {
    .circle {
      top: 30px;
      &:after {
        top: -33px;
        height: 30px;
      }
    }
    h1 {
      margin-top: -8px;
      margin-bottom: -8px;
    }
  }
  .schedule {
    .cal-time {
      &.events {
        padding: 24px 40px 24px 24px;
        margin-bottom: 25px;
        .timing {
          .border-dashed {
            height: 50px;
          }
          .event-time {
            &:last-child {
              &:before {
                height: 50px;
              }
            }
          }
        }
        .schedule-event {
          .names {
            margin-bottom: 15px;
          }
          .schedule-description {
            font-size: 140%;
          }
        }
      }
    }
  }
  .agency {
    .girl-boy {
      right: 0;
    }
    .icon-collection {
      .about-icon {
        margin: 0;
        margin-bottom: 40px;
      }
    }
    &.agency-content {
      .blue-img {
        left: -18%;
      }
    }
    &.video {
      .d-inline-block {
        .bg-video {
          width: 60%;
        }
      }
    }
  }
  .yoga {
    &.format {
      .girl-img-container {
        min-height: 453px;
        .girl-yoga {
          width: 500px;
        }
      }
    }
  }
  .gym {
    h1 {
      margin-top: -14px;
    }
    h2 {
      margin-top: -11px;

    }
  }
  .app2 {
    &.quality {
      .set-height {
        min-height: 592px;
        .mobile2 {
          width: 430px;
          top: -29px;
        }
      }
    }
    &.services {
      .service {
        padding: 40px 25px;
        .service-feature {
          .feature-text {
            margin-bottom: 18px;
          }
        }
      }
      .img-block {
        margin-bottom: 25px;
        img {
          height: 45px;
        }
      }
    }
  }
  .saas2 {
    .set-center-div {
      min-height: unset;
      .side-img {
        right: 2%;
        img {
          height: 455px;
        }
      }
    }
  }
  .header {
    .wave-orange {
      width: 240px;
      height: 25px;
      &:before, &:after {
        width: 240px;
        height: 25px;
      }
    }
    &.app2 {
      .img-mobile {
        .headaer-image {
          width: 315px;
        }
      }
    }
    &.event {
      .set-bottom {
        bottom: 30px;
      }
    }
    &.resume {
      .bg-pink {
        img {
          max-height: 80vh;
        }
      }
    }
    .saas2-content {
      .set-abs {
        .img-dextop {
          height: 600px;
        }
      }
    }
    &.saas2 {
      .header-text {
        margin-bottom: 30px;
      }
      .header-sub-text {
        margin-bottom: 16px;
        margin-top: 40px;
      }
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      border-top: none;
      .product-filter-content {
        .search-count {
          width: 100%;
          border-left: 1px solid $border-white;
          border-bottom: 1px solid $border-white;
          border-top: 1px solid $border-white;
          text-align: center;
        }
      }
      .popup-filter, .product-filter-content {
        .collection-view {
          width: 15%;
          display: flex;
        }
        .collection-grid-view {
          width: 30%;
          display: flex;
        }
        .product-page-per-view {
          width: 30%;
        }
        .product-page-filter {
          width: 55%;
        }
      }
    }
  }
  .product-right {
    .product-icon {
      .product-social {
        li {
          padding-right: 10px;
        }
      }
    }
  }
  .collection-filter-block {
    .product-service {
      .media {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }



  .blog-page {
    .blog-media {
      .blog-right {
        h4 {
          line-height: 1.2;
          margin-bottom: 10px;
        }
        h6 {
          margin-bottom: 5px;
        }
        ul {
          margin-bottom: 10px;
        }
        p {
          line-height: 1.4;
        }
      }
    }
  }
  .pro_sticky_info {
    padding: 10px;
  }
  .is_stuck {
    margin-top: 30px;
  }

  // breadcrimb  start //
  .breadcrumb-section-main {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 1250px) {
  .about {
    &.app1 {
      .mobile {
        right: 5%;
        &.mobile-img {
          .mobile-screen {
            height: 650px;
          }
        }
      }
    }
  }


}

@media only screen and (max-width: 1199px) and (min-width: 576px) {
.resume{
  .counter-container{
    padding-bottom: 30px;
    &+.counter-container{
      border-left: 1px solid;
      &+.counter-container{
        border-top: 1px solid;
        border-left: 0;
        padding-bottom: 0;
        padding-top: 30px;
        &+.counter-container{
          border-left: 1px solid;
        }
      }
    }
  }
}
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .app2 {
    &.pricing {
      .price-container {
        .feature-icon {
          :before {
            font-size: 45px;
          }
        }
        .service-feature {
          padding: 30px 0 30px 0;
        }
        .price-feature-container {
          padding: 35px 0 35px 0;
        }
      }
    }
  }

  .screenshot{
    .screen-container{
      img{
        width: 380px;
        height: 580px;
      }
    }
    .swiper-slide{
      img{
        height: 455px;
      }
    }
  }

  header.agency{
    nav{
      ul{
        li .dropdown{
          color: #7e8ec7;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .pricing.yoga .price-container.price-margin{
    margin: 35px 15px;
  }
  .copyright {
    .link-horizontal{
      ul li{
        padding-right: 20px;
      }
    }
  }
  .agency.testimonial-bg {
    background-size: contain;
  }
  .header .bg.agency {
    background-position: bottom;
  }
  header.nav-lg {
    padding: 1.2rem 1rem;
  }
  .ecommerce{
    .owl-nav {
      [class*=owl-]{
        &.owl-prev{
          bottom: 20%;
          top: unset;
          left: unset;
          right: 80px;
        }
        &.owl-next{
          bottom: 20%;
          top: unset;
        }
      }
    }
    &.ecommerce-home{
      .ecommerce-home-slider{
        .owl-item,.bg-size{
          min-height: 500px;
        }
      }
    }
  }
  .parallax-banner .banner-contain {
    max-width: 50%;
  }
  .parallax-banner {
    .banner-contain {
      max-width: 50%;
    }
    .full-banner {
      height: 350px;
      background-attachment: fixed;
    }
  }
  .app2{
    &.services ,&.screenshot {
      .animated-bg i {
        display: none;
      }
    }
  }
  .services .service .img-block {
    margin-bottom: 10px;
  }
  .copyright.yoga, .copyright.resume, .copyright.creative {
    padding: 30px 0;
  }
  .breadcrumb-section {
    padding: 170px 0;
  }
  header {
    nav {
      ul {
        .mega-menu {
          .mega-menu-container {
            min-width: 85VW;
          }
        }
      }
    }
    &.wedding{
      .logo-abs{
        width: 23%;
      }
      .navbar {
        > ul {
          > li {
            > a {
              text-transform: capitalize;
              font-size: 13px;
            }
          }
        }
      }
    }
    &.yoga {
      nav{
        ul{
          li{
            > a{
              text-transform: capitalize;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .gym, .yoga, .creative {
    .trainers-slider {
      &.owl-carousel {
        .owl-stage-outer {
          min-height: 400px !important
        }
      }
    }
  }
  .portfolio-metro {
    h1 {
      font-size: 60px;
      margin-bottom: 30px;
    }
  }
  .m-b-50 {
    margin-bottom: 30px;
  }
  .wedding.bg {
    &.attend-bg {
      padding-bottom: 180px;
    }
  }
  .coming-soon .count h2 {
    padding: 0 15px;
  }
  .saas1 {
    &.howitwork {
      .work-content {
        img {
          width: 50%;
        }
      }
      .work-tab {
        .nav-pills {
          .nav-link {
            padding: 0 30px;
            img {
              height: 40px;
            }
          }
        }
      }
    }
    &.build-bg {
      .build-box {
        ul {
          li {
            font-size: 15px;
          }
        }
      }
    }
    &.pricing {
      .plan-box {
        .price-box {
          padding: 20px;
          margin: 16px 9px;
          ul {
            margin-top: 10px;
            margin-bottom: 20px;
          }
          h4.no-weight {
            margin: 5px 0 0 0;
          }
        }
      }
    }
  }
  .event {
    &.testimonial {
      .center-content {
        img {
          height: 25px;
        }
      }
      .testimonial-msg {
        width: 80px;
        .msg-box {
          height: 45px;
          width: 45px;
          right: -22px;
          top: -25px;
        }
      }
    }
  }
  .register-page {
    h3 {
      margin-bottom: 10px;
    }
    .text-sub {
      margin-bottom: 12px;
    }
    .theme-card {
      padding: 18px;
      .theme-form {
        input, textarea {
          padding: 10px 12px;
          margin-bottom: 18px;
        }
      }
    }
  }
  //inner pages start
  .pricing {
    &.saas2 {
      .price-container {
        &.price-margin {
          margin: 25px 15px;
        }
      }
    }
    &.yoga {
      .price-container {
        &.price-margin {
          margin: 35px 0px;
        }
      }
    }
  }
  .event {
    .format {
      .format-sub-text {
        margin-bottom: 25px;
        .about-para {
          margin-bottom: 15px;
        }
      }
      .format-head-text {
        margin-bottom: 25px;
      }
    }
  }
  .cart-section {
    tbody {
      tr {
        td {
          min-width: 140px;
          .qty-box {
            .input-group {
              .form-control {
                width: 52px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 25px;
        }
      }
    }
  }
  //inner pages end
  .dropdown-sec {
    .dropdown-content {
      &.dropdown-size {
        min-width: 85VW;
      }
    }
    .dropdown-content, .dropdown-content li ul {
      left: 0;
    }
  }
  .counter {
    .counters {
      .counter-img {
        height: 35px;
      }
    }
  }
  .header {
    .rotate {
      top: 15%;
      right: 12%;
      img {
        width: 350px;
      }
    }
    .wedding-content {
      .girl {
        left: 6%;
        img {
          width: 450px;
        }
      }
    }
    .header8-content {
      .slider-1 {
        .owl-nav {
          transform: translate(3%, 60%);
          left: -20%;
          bottom: -63%;
          .owl-next {
            transform: translate(30%, 30%);
          }
        }
      }
    }
    .saas2-content {
      .set-abs {
        right: 1%;
        .img-dextop {
          height: 610px;
        }
      }
    }
    &.saas1 {
      h1 {
        margin-top: -13px;
      }
    }
    .bg {
      &.gym-header {
        .header-sub-text {
          p {
            padding: 0;
          }
        }
      }
    }
  }
  .subscribe {
    .button-primary {
      button {
        padding: 16px 40px;
        font-size: 80%;
      }
    }
    .form-group {
      input {
        padding: 24px 35% 24px 35px;
        font-size: 15px;
      }
    }
  }
  footer {
    .footer-lists {
      &.contacts {
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
  .event, .saas2 {
    &.testimonial {
      .quotes {
        .right-quote {
          right: 0;
        }
        .left-quote {
          left: -30px;
        }
      }
      .testimonial {
        .owl-theme {
          .owl-next {
            right: -50px;
          }
          .owl-prev {
            left: -50px;
          }
        }
      }
    }
  }
  .event {
    &.speaker {
      .speker-container {
        .team-img {
          margin-bottom: 20px;
        }
        &:nth-child(n+3) {
          .employee {
            margin-bottom: 0;
          }
        }
      }
    }
    &.booking {
      .form-group input {
        font-size: 14px;
        padding: 11px 20px;
      }
    }
    &.contact {
      .plane, .plane2 {
        display: none;
      }
    }
  }
  .event, .gym, .agency, .resume {
    &.pricing {
      .price-container {
        .price-feature-container {
          padding: 30px 0 30px 0;
          .price-value {
            margin-bottom: 35px;
          }
        }
        .price-features {
          margin-bottom: 30px;
          .price-feature {
            margin-bottom: 10px;
          }
        }
      }
      .set-border {
        margin: 20px 20px;
      }
    }
  }
  .music {
    h2 {
      margin-top: -10px;
    }
    div {
      .album-list {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .cd-animation {
      height: 290px;
    }
    &.blog{
      .img-container {
        .blog-info{
          padding: 10px 15px;
        }
      }
    }
  }
  .wedding {
    &.format {
      .format-head-text {
        margin-bottom: 30px;
      }
      .format-sub-text {
        margin-bottom: 30px;
        .about-para {
          margin-bottom: 25px;
        }
      }
    }
    .wedding-banner {
      .frem {
        width: 60%;
      }
    }
    .wedding-banner {
      .text-container {
        .couple-text {
          margin-bottom: 25px;
          .banner-text {
            font-size: 65px;
          }
          .banner-text {
            &.small {
              font-size: 50px;
            }
          }
        }
        .place {
          font-size: 30px;
        }
      }
    }
    .wedding-time {
      margin-bottom: 50px;
    }
    &.copyright {
      .link-horizontal {
        ul {
          margin-bottom: 20px;
          justify-content: center;
        }

      }
      .copyright-text {
        text-align: center !important;
      }
    }
  }
  .yoga {
    &.testimonial {
      .testimonial-info {
        .testimonial-para {
          font-size: 16px;
          padding: 0;
        }
      }
    }
  }
  .resume {
    &.services {
      .service {
        padding: 25px 15px;
        .img-block {
          margin-bottom: 20px;
          img {
            height: 40px;
          }
        }
        .service-feature {
          .feature-text {
            margin-bottom: 15px;
          }
          p {
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
  .sponsor {
    .sponsor-img {
      margin-bottom: 50px;
    }
  }
  .agency {
    .girl-boy {
      right: 6%;
      img {
        width: 360px;
      }
    }
    .icon-collection {
      .about-icon {
        margin-bottom: 25px;
        a {
          padding: 25px 12px;
          img {
            margin-bottom: 15px;
            height: 50px;
          }
        }
      }
    }
    &.agency-content {
      .about-img {
        width: 200px;
      }
      .blue-img {
        width: 450px;
        left: -20%;
      }
      .agency-header-center-container {
        .agency-head {
          .agency-head-text {
            margin-top: -14px;
          }
        }
      }
    }
    &.blog{
      .img-container{
        .blog-info{
          p{
            line-height: 22px;
          }
        }
      }
    }
  }
  .saas1 {
    &.build-bg {
      min-height: 680px;
    }
    .title {
      img {
        height: 20px;
      }
    }
  }
  .saas2 {
    .set-center-div {
      .side-img {
        right: 2%;
        img {
          height: 400px;
        }
      }
    }
    .offers-container {
      .offer-heading-text {
        margin-bottom: 20px;
      }
      .bottom-border {
        margin-bottom: 50px;
      }
      .offers-content {
        margin-bottom: 35px;
      }
    }
    &.testimonial {
      .msg-box {
        display: none;
      }
      .quote-margin {
        margin-left: 0
      }
    }
  }
  .blog {
    &.wedding {
      .blog-container {
        .blog-date {
          margin-bottom: 5px;
        }
        .blog-place {
          margin-bottom: 12px;
        }
        .blog-para {
          margin-bottom: 12px;
        }
      }
    }
  }
  .product-right {
    .product-icon {
      .product-social {
        margin-bottom: 10px;
      }
    }
  }
  .product-description-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    .product-icon {
      .product-social {
        li {
          padding-right: 4px;
        }
      }
      .wishlist-btn {
        span {
          padding-left: 4px;
        }
      }
    }
  }
  footer{
    &.app2{
      h5{
        font-size: 14px;
        padding-bottom:55px;
      }
    }
  }



  // inner pages //
  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 186px;
        }
      }
    }
  }
  .blog-detail-page {
    .comment-section {
      li {
        padding-top: 45px;
        padding-bottom: 45px;
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .img-wrapper, .img-block {
          width:50%;
        }
      }
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width:100%;
          border-left:1px solid $round-border;
          border-bottom: 1px solid $round-border;
        }
        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left:1px solid $round-border;
        }
        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 26%;
          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }
        .product-page-filter {
          width: 54%;
        }
        .product-page-per-view {
          width: 30%;
        }
      }
    }
  }
  .cart-section {
    tbody {
      tr {
        td {
          min-width: 140px;
          .qty-box {
            .input-group {
              .form-control {
                width: 52px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 25px;
        }
      }
    }
  }
  .collection-collapse-block {
    .collection-collapse-block-content {
      .collection-brand-filter {
        .category-list {
          li {
            &:first-child {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  .collection-filter-block {
    margin-bottom: 20px;
    .product-service {
      .media {
        .media-body {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .product-right {
    h2 {
      font-size: 20px;
    }
  }
  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 30px;
          p {
            line-height: 1.5;
          }
        }
      }
    }
  }
  .search-product {
    > div {
      &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) {
        margin-top: 30px;
      }
    }
  }
  .blog-page {
    .blog-media {
      margin-bottom: 20px;
      .blog-right {
        display: block;
        margin-top: 15px;
        p {
          line-height: 1.3;
        }
      }
    }
  }
  .tab-product {
    .tab-content {
      &.nav-material {
        .single-product-tables {
          table {
            width: 50%;
          }
        }
      }
    }
  }
  .product-description-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    .product-icon {
      .product-social {
        li {
          padding-right: 4px;
        }
      }
      .wishlist-btn {
        span {
          padding-left: 4px;
        }
      }
    }
  }
  .product-form-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    .timer {
      padding-left: 17px;
      span {
        width: 55px;
      }
    }
    .product-buttons {
      .btn-default {
        padding: 7px 13px;
      }
    }
  }
  .product-accordion {
    .single-product-tables {
      table {
        width: 70%;
      }
    }
  }
  .tab-product {
    .flex-column {
      flex-direction: unset !important;
      justify-content: center;
    }
    .nav-material {
      &.nav-tabs {
        border-bottom: 1px solid $round-border;
        border-right: 0;
        .nav-item {
          .nav-link {
            &.active {
              background-color: unset;
            }
          }
        }
      }
    }
  }
  .tab-border {
    padding-top: 0;
  }
  .payment-box {
    .float-right {
      float: unset !important;
    }
    button {
      margin-top: 10px;
    }
  }

  // breadcrumb type css start //
  .breadcrumb-section {
    padding: 170px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    .bg {
      &.header1 {
        h1 {
          line-height: 50px;
        }
      }
    }
  }
  .services {
    .service {
      .service-feature {
        p {
          font-weight: unset;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .main-menu{
    > li{
      >ul,.mega-menu-container{
        display: none;
      }
    }
  }
  header {
    nav {
      ul {
        .dropdown {
          &:before {
            right: 0;
          }
        }
        ul, .mega-menu-container {
          position: absolute;
        }
        .sub-menu {
          ul {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            z-index: 1;
            top: -5px;
            left: 100%;
            display: none;
          }
        }
      }
    }
  }
  .dropdown-main-container {
    padding: 20px 0;
    .container {
      max-width: 100%;
    }
  }
  .dropdown-sec {
    .dropdown, .dropdown-submenu {
      position: relative;
      display: inline-block;
    }
    a {
      text-transform: capitalize;
    }
    .right-border {
      border-right: 1px solid $white4;
    }
    .dropdown-content, .dropdown-content li ul {
      display: none;
      transition: 0.8s;
      position: absolute;
      right: 0;
      z-index: 1;
    }
    .drop-down-design {
      margin-top: 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
    .dropdown-content {
      &.dropdown-size {
        min-width: 1030px;
      }
      ul {
        width: 100%;
      }
    }

    .dropdown {
      &:hover {
        .dropdown-content {
          display: block;
          transition: 0.8s;
        }
      }
    }

    .dropdown-submenu {
      position: relative;
      width: 100%;
      .dropdown-menu {
        border: none;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        top: 0;
        left: 100%;
        margin-top: -1px;
      }
      &:hover {
        > ul {
          display: block;
          transform: translateY(-10px);
          transition: 0.8s;
        }
      }
    }
  }
  .dropbtn {
    color: $primary;
    > i {
      margin-left: 10px;
    }
  }
  .music{
    &.sponsor {
      .row {
        > div {
          &:last-child, &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
            .sponsor-img {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .res-width{
    width: 100%;
  }
  .mega-menu{
    .mega-menu-container{
      .row{
        .col{
          width: 20%;
        }
      }
    }
  }
  .resize {
    min-width: 600px !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .speaker {
    .team-img {
      .social {
        ul {
          li {
            margin-right: 10px;
            a {
              i {
                height: 25px;
                width: 25px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .agency, .gym {
    &.format {
      .center-content {
        &.unset-height {
          padding: 0;
        }

      }
    }
    &.footer2 {
      .set-first {
        order: -1;
      }
      .set-last {
        order: 5;
        margin-top: 30px;
      }
    }
  }
  .saas1 {
    &.testimonial {
      .testimonial-box {
        padding: 30px 20px;
      }
    }
  }
  .resume {
    &.about {
      .col-md-3 {
        padding: 0;
      }
    }
  }
  footer{
    &.app2{
      .subscribe{
        &.subscribe-2{
          .form-group{
            input{
              margin-top: 15px;
            }
          }
        }
      }
    }
    .logo-sec{
      .footer-logo{
        margin-bottom: 20px;
      }
    }
    &.gym{
      h5{
        padding-bottom: 35px;
      }
    }
  }

  // breadcrumb section type //
  .breadcrumb-section-main {
    .breadcrumb {
      justify-content: center;
    }
    .breadcrumb-text {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  .blog-sidebar {
    .post-container {
      width: 50%;
      float: left;
    }
  }
  .music{
    &.sponsor {
      .row {
        > div {
          &:nth-last-child(3), &:nth-last-child(2), &:nth-last-child(1){
            .sponsor-img {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px)   {
  .mega-menu-container {
    .row {
      .col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  header {
    nav {
      .menu-head {
        color: #656565 !important;
    font-weight: 400 !important;
    border-bottom: 0 !important;
    &:after {
      position: absolute;
      content: "+";
      right: 20px;
    }
      }
    }
  }
  .white-header {
    header {
      nav {
        .main-menu {
          > li {
            > a {
              color: black !important;
            }
          }
        }
      }
    }
  }
  .p-4col-width {
    .p-width {
      max-width: 33.33%;
      flex: 33.33%;
      margin-bottom: 12px;
    }
  }
  .m-layout {
    .m-width {
      &.m-4-col{
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 33.33%;
      }
    }
  }
  .music.copyright.copyright-bg {
    .copyright-text.text-right{
      text-align: right !important;
    }
    .container {
      max-width: 100%;
    }
  }
  .ecommerce{
    &.ecommerce-home{
      .ecommerce-home-slider{
        .owl-item,.bg-size{
          min-height: 300px;
        }
      }
    }
  }
  .layout-ecommerce{
    section {
      padding: 60px 0;
    }
    .ecommerce-footer{
      padding-top: 60px;
    }
  }
  .ecommerce{
    &.ecommerce-home {
      padding-top: 80px;
    }
  }
  .ecommerce-footer{
    .col-md-12{
      text-align: center;
      margin-bottom: 30px;
    }
  }
  header{
    &.ecommerce {
      .logo-erapper{
        ~.responsive-btn{
          position: absolute;
          right: 0;
        }
      }

      nav{
        .top-header-right {
          margin-right: 40px;

          ul li {
            display: inline-block;
            a {
              padding: 12px;
            }
          }
        }
        ul.main-menu {
          > li {
            > a {
              padding-top: 15px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
  header nav ul li > a:hover {
    color: $black;
  }
  header.ecommerce .navbar{
    position: fixed;
  }
  .tab-product .tab-content.nav-material p{
    text-align: center;
  }
  .pricing {
    &.saas2 {
      .price-container {
        &.price-margin {
          margin: 35px 25px;
        }
      }
    }
  }
  .saas1-header{
    .slider-logo{
      display: none;
    }
  }
  footer .logo-sec .footer-para .para-address {
    line-height: 1.6;
  }
  .gym{
    .trainers-slider{
      h3{
        font-size: 20px;
      }
    }
  }
  .agency.service-bg{
    padding-top: 0;
  }
  .navbar{
    display: block;
    .responsive-btn{
      &~ul{
        .dropdown{
          &~ul,&~.mega-menu-container{
            display: none;
          }
        }
      }
    }
  }
  header {
    nav{
      ul{
        li{
          ul{
            display: none !important;
          }
        }
        ul{
          li{
            &:hover,&:focus,&:active{
              a{
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
    &.yoga{
      .logo-abs{
        left: 0;
        transform: unset;
      }
    }
    nav {
      ul {
        width: 100%;
        padding: 15px 0;
        li {
          display: block;
          a {
            width: 100%;
            text-align: left;
            color: $primary;
            padding: 12px 18px;
          }
        }
        ul {
          padding: 0!important;
          li{
            padding-left: 15px !important;
            a{
              color: #656565 !important;
              font-weight: 400 !important;
            }
          }
        }
        .sub-menu {
          > a {
            &:before {
              content: unset;
            }
          }
          ul {
            box-shadow: unset;
          }
        }
        .mega-menu {
          .mega-menu-container {
            padding: 6px 0;
            min-width: 100%;
            box-shadow: unset;
          }
        }
      }
      .menu-head {
        color: $black;
        margin-bottom: 0;
      }
    }
    .navbar {
      position: fixed;
      transition: 0.5s;
      z-index: 9;
      width: 350px;
      right: -350px;
      background: $white;
      overflow-Y: scroll;
      height: 100vh;
      top: 0;
      padding: 0;
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    }
    .responsive-btn {
      display: inline-block;
    }
    &.yoga{
      .responsive-btn {
        padding: 12px;
        a{
          color: $white;
        }
      }
    }
    .navbar {
      .responsive-btn {
        display: block;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid rgb(221, 221, 221);
        h5 {
          text-align: right;
          font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1920 - 300)));
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
  .menu-container{
    li{
      a{
        &:before{
          display: none;
        }
      }
    }
  }
  .gym, .yoga {
    .trainers-slider {
      &.owl-carousel {
        .owl-stage-outer {
          min-height: 330px !important;
        }
      }
    }
  }
  .yoga {
    &.copyright {
      hr {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    &.header {
      .bg {
        height: unset;
      }
      .center-text {
        height: unset;
        min-height: 70vh;
      }
    }
    &.event {
      .event-container {
        .yoga-circle {
          height: 80px;
          width: 80px;
          padding: 17px 25px;
        }
      }
    }
  }
  .saas2 {
    &.quick-sol {
      .set-center-div {
        min-height: unset;
      }
    }
    &.pricing {
      .price-container {
        .price-feature-container {
          margin-top: -100px;
        }
      }
    }
  }
  .saas1 {
    &.service {
      .about-chat {
        .service-container {
          .feature-content {
            hr {
              margin: 0 auto;
            }
          }
        }
      }
    }
    .about-chat {
      .chat-box {
        text-align: center;
      }
    }
    .brand-slider {
      img {
        padding: 0 30px;
      }
    }
    &.build-bg {
      min-height: unset;
      .build-box {
        text-align: center;
      }
      .container {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    &.testimonial {
      .testimonial-slider {
        .owl-carousel {
          .owl-item {
            .testimonial-box {
              img {
                height: 30px;
              }
            }
          }
        }
      }
      .testi-profile {
        img {
          padding-left: 0;
        }
      }
    }
  }
  .filter-section {
    .filter-container {
      padding: 0 0 20px;
    }
  }
  .header {
    &.resume {
      .bg-pink {
        img {
          width: 100%;
          max-height: unset;
        }
      }
    }
  }
  .gym {
    h1 {
      line-height: 1.4;
    }
    .brand-slider {
      img {
        padding: 0 15px;
      }
    }
    .about-img {
      .format-img {
        width: unset;
      }
    }
    .borders-around {
      .bg-black {
        &:before {
          top: -20px;
          left: -20px;
        }
        &:after {
          bottom: -20px;
          right: -20px;
        }
      }
    }
  }
  .testimonial.event {
    .rating {
      .stars {
        i {
          font-size: 13px;
          padding: 0;
        }
      }
    }
  }
  .contact {
    .bg-white {
      form {
        padding: 60px 30px;
      }
    }
  }
  .title {
    margin-bottom: 25px;
  }
  .schedule {
    .event-container {
      padding-right: 30px;
      max-height: 400px;
    }
  }
  .event {
    .event-content {
      .container {
        max-width: unset;
      }
    }
  }
  .wedding {
    .wedding-time {
      margin-bottom: 0;
    }
    .simple-text {
      text-align: center;
      background-color: hsla(0, 0%, 100%, 0.9);
      padding: 50px;
      .rightfadediv {
        margin: 0 auto;
      }
      * {
        animation: none !important;
      }
    }
    &.bg.attend-bg {
      padding-bottom: 100px;
    }
    .wedding-banner {
      .text-container {
        .couple-text {
          margin-bottom: 15px;
          .banner-text {
            font-size: 50px;
          }
          .banner-text {
            &.small {
              font-size: 40px;
            }
          }
        }
        .address{
          font-size: 13px;
          margin-top: 5px;
        }
        .place {
          font-size: 20px;
        }
      }
    }
  }
  footer {
    &.footer2 {
      &.saas2 {
        background-position: left;
        margin-top: -170px;
      }
    }
  }
  .testimonial {
    &.saas2 {
      .testimonial-msg {
        width: 80px;
        .msg-box {
          width: 40px;
          height: 40px;
          right: -20px;
          img {
            width: 25px !important;
          }
        }
      }
    }
  }
  .breadcrumb-section {
    .breadcrumb {
      justify-content: center;
    }
    .breadcrumb-text {
      text-align: center;
    }
  }
  .saas2 {
    .saas2.quick-sol .set-center-div
    &.services {
      .saas2-services {
        .row {
          .service-container:nth-child(n+4) {
            margin-top: 30px;
          }
        }
      }
    }
    .offers-container {
      .offers-content {
        .offer-main-text {
          margin-bottom: 0;
        }
      }
      .bottom-border {
        margin-bottom: 30px;
      }
    }
    &.laptop-slider {
      .slider-container {
        .trainers-slider {
          &.owl-carousel {
            .owl-item {
              &.active {
                &.center {
                  .item {
                    &:before {
                      width: calc(100% + 0px);
                      bottom: -11px;
                      left: 0;
                    }
                    &:after {
                      bottom: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .slider-container {
    width: 60%;
    margin: 0 auto;
    .trainers-slider {
      .item {
        margin: 0 40px;
      }
    }
  }
  .saas2 {
    &.laptop-slider {
      .slider-container {
        .trainers-slider {
          &.owl-carousel {
            .owl-item.active.center {
              .item {
                img {
                  border: 10px solid #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  .blog-sidebar {
    .sidebar-container {
      .post-container {
        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }
  }
  .about.app1 {
    .counters {
      img {
        margin-bottom: 15px;
        height: 35px;
      }
      .abouts {
        padding: 25px 0;
      }
    }
    h3 {
      margin-bottom: 0;
    }
  }
  .app2 {
    &.team {
      .team-slider {
        .team-container {
          padding: 25px 10px;
        }
      }
    }
    &.brand-sliders{
      padding-bottom: 45px;
    }
  }
  //inner pages start
  .blog-sec {
    &.blog {
      &.sider{
        .container{
          margin-bottom: 0;
        }
      }
      .container {
        &.no-side {
          margin-bottom: -28px;
        }
      }

    }
    .blog-side {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px dotted $white2;
    }
  }
  .cart-section, .wishlist-section {
    .cart-table {
      thead {
        th {
          &:last-child {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 135px;
          &:last-child {
            display: none;
          }
        }
      }
    }
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 15px;
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 0;
        }
      }
    }
  }
  .checkout-page {
    .checkout-form {
      .checkout-details {
        margin-top: 30px;
      }
    }
  }
  .portfolio-section {
    .portfolio-text {
      .head-text {
        line-height: 60px;
      }
    }
  }
  .login-page {
    .right-login {
      margin-top: 20px;
    }
  }
  .team-sec {
    &.team-grid {
      .text-center {
        &.d-flex {
          display: block !important;
        }
      }
    }
  }
  .payment-box {
    button {
      margin-top: 0;
      margin-left: 10px;
    }
  }
  //inner pages end
  section, footer {
    padding: 60px 0;
  }
  .p-t-100 {
    padding-top: 60px !important;
  }
  .p-b-100 {
    padding-bottom: 60px !important;
  }
  .pricing {
    .price-container {
      .feature-icon {
        &:before {
          font-size: 40px;
        }
      }
    }
  }
  .videos {
    .video-description {
      h3 {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
  .m-t-40 {
    margin-top: 30px;
  }
  .m-b-40 {
    margin-bottom: 30px;
  }
  .m-t-50 {
    margin-top: 35px;
  }
  header {
    &.top-logo {
      .logo-sec {
        &.text-center {
          text-align: left !important;
        }
      }
    }
    &.gym{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $black;
              }
            }
          }
        }
      }
    }
    &.agency{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: #10266b;
              }
            }
          }
        }
      }
    }
    &.app1{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
    &.app2{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
    &.event{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
    &.music{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
    &.wedding{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
    &.yoga{
      nav{
        ul{
          li{
            > a{
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
  .header {
    .bg {
      height: 650px;
    }
    &.saas1 {
      .bg {
        height: 500px;
      }
      .center-text {
        height: calc(500px - 63px);
        margin-top: 55px;
      }
    }
    .center-text {
      height: calc(650px - 63px);
      margin-top: 63px;
    }
    .header-text {
      margin-bottom: 30px;
    }
    .header-top-line {
      margin-bottom: 35px;
    }

    .set-square {
      .left {
        height: 250px;
        &:before {
          width: 250px;
        }
      }
      .right {
        width: 250px;
      }
    }
    .right-line {
      left: calc(50% + 125px);
      height: 260px;
    }
    ul {
      li {
        a {
          i {
            font-size: 25px;
            color: $primary;
            display: flex;
            height: 48px;
            width: 48px;
          }
        }
      }
    }
    .rotate {
      top: 50%;
      right: 12%;
      transform: translate(0, -50%) rotate(45deg);
      img {
        width: 250px;
      }
    }
    .squares {
      display: none;
    }
    &.app2 {
      .img-mobile {
        position: relative;
        text-align: right;
        margin-right: -50px;
        .headaer-image {
          width: unset;
          height: 500px;
          margin-top: 10px;
        }
      }
    }
    .wave-orange {
      display: none;
    }
    .bg {
      &.app2-header {
        .header-text {
          padding-bottom: 10px;
        }
      }
    }
    .event-content {
      .h1-margin {
        margin-bottom: 25px;
      }
      .header-sub-text {
        margin-bottom: 20px;
      }
      .set-bottom {
        bottom: 15px;
      }
      .counter-container {
        h2 {
          margin-top: -9px;
        }
      }
    }
    .header8-content {
      .slider-1 {
        .owl-nav {
          display: none;
        }
      }
    }
    .saas2-content {
      .bottom-content {
        bottom: 3%;
      }
      .set-abs {
        .img-dextop {
          display: none;
        }
      }
      .center-text {
        div {
          text-align: center;
          width: 100%;
        }
        .link-horizontal {
          ul {
            justify-content: center;
          }
        }
        .header-text {
          margin-bottom: 25px;
        }
        .header-sub-text {
          margin-bottom: 20px;
        }
      }
      .move-right-left, .move-up-down {
        display: none;
      }
    }
    &.saas2 {
      h1 {
        margin-top: -20px;
        margin-bottom: 0;
      }
    }
    .bg {
      &.gym-header {
        .header-sub-text {
          padding: 0;
        }
      }
    }
  }
  .about {
    &.app1 {
      .mobile {
        &.mobile-img {
          display: none;
        }
      }
      .set-padding {
        padding: 0 15px;
      }
    }
  }
  .app1 {
    p {
      line-height: 26px;
    }
    &.screenshot {
      .container {
        margin-bottom: 0;
      }
      .screen-container {
        display: none;
      }
      .swiper-slide {
        img {
          height: auto;
          width: auto;
        }
      }
    }
    &.services {
      .service {
        padding: 30px 15px;
        .img-block {
          margin-bottom: 7px;
          img {
            height: 45px;
          }
        }
      }
    }
  }
  .app2 {
    h1 {
      line-height: 42px;
    }
    .abouts {
      .set-border {
        margin-bottom: 25px;
      }
      .logo {
        height: 100px;
      }
    }
    h4 {
      margin-bottom: 15px;
    }
    &.quality {
      .set-height {
        min-height: unset;
        .mobile1, .mobile2 {
          width: auto;
          top: -23px;
          height: 350px;
        }
      }
    }
    &.services {
      .service {
        padding: 30px 18px;
      }
      .img-block {
        margin-bottom: 25px;
      }
    }
    .borders-before {
      margin-bottom: 15px;
    }
    &.format {
      .format-head-text {
        margin-bottom: 35px;
      }
      .format-sub-text {
        margin-bottom: 20px;
      }
      .m-t-50 {
        margin-top: 24px;
      }
    }
  }
  .title {
    .title-img {
      margin-bottom: 15px;
    }
    &.title2 {
      h6 {
        &.main-text {
          margin-bottom: 12px;
        }
      }
      .sub-title {
        line-height: 40px;
        margin-bottom: 15px;
      }
      .title-text {
        line-height: 40px;
      }
    }
  }
  .pricing {
    &.app1 {
      .price-container {
        .service-feature {
          .pricing-img {
            margin-bottom: 20px;
          }
        }
        .price-feature-container {
          padding: 30px 0 80px 0;
          .price-value {
            margin-bottom: 25px;
          }
        }
        .price-features {
          margin-bottom: 20px;
          .price-feature {
            margin-bottom: 10px;
          }
        }
      }
    }
    &.app2 {
      .price-container {
        .service-feature {
          padding: 35px 0 35px 0;
          .pricing-img {
            margin-bottom: 20px;
          }
        }
        .price-feature-container {
          padding: 35px 0 35px 0;
        }
      }
      .format {
        .format-head-text {
          margin-bottom: 35px;
          .about-font-header {
            font-size: 220%;
          }
        }
        .format-sub-text {
          margin-bottom: 35px;
        }
      }
    }
    &.service-2 {
      .service-feature {
        padding: 40px 0 20px 0;
        img {
          height: 50px;
        }
      }
      .service-feature2 {
        padding: 40px 0 40px 0;
        .features {
          h6 {
            margin-bottom: 15px;
          }
        }
        .price {
          span {
            font-size: 45px;
          }
        }
      }
    }
  }
  .m-b-30 {
    margin-bottom: 20px;
  }
  .m-b-45 {
    margin-bottom: 25px;
  }
  .m-t-50 {
    margin-top: 30px;
  }
  .m-b-35 {
    margin-bottom: 22px;
  }
  .download {
    .information {
      p {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
  }
  .borders-before {
    margin-bottom: 25px;
  }
  .app2 {
    .icon-collection {
      .about-icon {
        .center-content {
          img {
            max-height: 35px;
            max-width: 35px;
          }
        }
      }
    }
  }
  .event, .music, .gym, .yoga {
    .icon-collection {
      .about-icon {
        margin: 0 12px;
        a {
          img {
            width: 25px;
          }
        }
      }
    }
  }
  .event, .music {
    &.booking {
      .form-inline{
        display: block;
        .col{
          +.col{
            margin-top: 15px;
          }
        }
      }
      .btn-default {
        padding: 15px 40px;
      }
    }
  }
  .app2 {
    &.about {
      padding-top: 60px;
      .set-height {
        min-height: 535px;
        .mobile1 {
          width: 300px;
        }
      }
    }
  }
  .event {
    .title {
      &.title3 {
        .main-title {
          margin-bottom: 20px;
        }
        .sub-title {
          p {
            line-height: 1.6;
          }
        }
      }
    }
    .count {
      h2 {
        font-size: 260%;
      }
    }
  }
  .gym, .yoga {
    .owl-carousel {
      &.trainers-slider {
        &.owl-carousel {
          .owl-item {
            &.active {
              &.center {
                .trainers-info {
                  padding-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  .gym {
    .gym-about-slider {
      text-align: center;
      .icon-collection {
        justify-content: center;
      }
    }
    h1 {
      margin-top: -11px;
    }
    &.format {
      .text-right {
        text-align: center !important;
      }
      .borders-before {
        margin-bottom: 5px;
      }
      .format-head-text {
        margin-bottom: 25px;
        .about-font-header {
          font-size: 260%;
        }
      }
      .format-sub-text {
        margin-bottom: 30px;
        .about-para {
          margin-bottom: 22px;
        }
      }
    }
    .calculate-bmi {
      tr {
        td {
          font-size: 14px;
          padding: 15px 0 15px 40px;
        }
      }
    }
  }
  .music {
    .img-height {
      img {
        width: 600px;
      }
    }
    &.header {
      .music-content {
        .center-text {
          height: calc(503px - 58px);
          margin-top: 58px;
        }
      }
    }
    .audioplayer {
      padding: 28px;
    }
    div {
      .album-list {
        padding: 35px 15px;
        margin-bottom: 30px;
        .list-head {
          margin-bottom: 22px;
        }
        .item-sublist {
          font-weight: 300;
        }
      }
    }
    &.artist {
      .album-artist {
        .cds {
          top: 85%;
          transform: translate(-50%, -35%);
        }
      }
      .artist-text {
        p {
          &.text-para {
            font-size: 125%;
          }
        }
      }
    }
    &.format {
      .format-small-text {
        margin-bottom: 4px;
      }
      .hash-video {
        font-size: 12px;
      }
      .format-head-text {
        margin-bottom: 8px;
        .about-font-header {
          font-size: 220%;
        }
      }
      .format-sub-text {
        margin-bottom: 25px;
        .sub-heading {
          margin-bottom: 25px;
          .sub-small-text {
            font-size: 14px;
          }
        }
        .sub-para {
          font-size: 12px;
        }
      }
      .link-horizontal {
        ul {
          li {
            a {
              &.icon-btn {
                i {
                  font-size: 14px;
                  height: 45px;
                  width: 45px;
                }
              }
            }
          }
        }
      }
      .watch {
        font-size: 95%;
      }
      .m-b-40 {
        margin-bottom: 15px;
      }
    }
    .form-footer {
      .form-group {
        input, textarea {
          padding: 15px 20px;
          font-size: 14px;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size: 14px;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-size: 14px;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-size: 14px;
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-size: 14px;
        }
      }
    }
    .left-side{
      padding-left: 50px;
      top: 50%;
    }
    .right-side{
      padding-right: 50px;
      top: 50%;
    }
    .img-height {
      height: unset;
      img {
        padding-top: 50px;
        position: unset;
        width: 800px;
      }
    }
    h2 {
      margin-top: -10px;
    }
    div {
      .album-list {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .cd-animation {
      height: 290px;
    }

  }
  .agency {
    &.header {
      .btn-default {
        padding: 12px 16px;
      }
      .header-top-line {
        margin-bottom: 20px;
      }
      .header-sub-text {
        margin-bottom: 25px;
        p {
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
      .circle-container {
        display: none;
      }
      .center-text {
        margin-left: 20px;
      }
      .girl-boy {
        right: 4%;
        img {
          width: 370px;
        }
      }
    }
    &.blog {
      .m-r-25 {
        margin-right: 15px;
      }
      .m-r-10 {
        margin-right: 5px;
      }
    }
    &.footer2 {
      .link-horizontal {
        ul {
          li {
            padding-right: 5px;
            &.borders-right {
              margin-right: 5px;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      .list {
        ul {
          li {
            padding-right: 5px;
            padding-left: 0;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      .footer-contant{
        .footer-headings{
          padding-bottom: 35px;
        }
      }
    }
    &.agency-content {
      .agency-header-center-container {
        margin-bottom: 30px;
      }
      .agency-para {
        margin-bottom: 25px;
      }
    }
    &.agency-content {
      .about-img {
        width: 160px;
      }
      .blue-img {
        width: 390px;
        left: -25%;
      }
    }
    &.testimonial-bg {
      background-position: inherit;
    }
    .para2 {
      letter-spacing: 1px;
    }
    .icon-collection {
      .about-icon {
        margin-bottom: 25px;
        a {
          padding: 25px 5px;
          img {
            margin-bottom: 15px;
            height: 40px;
          }
        }
      }
    }
    &.testimonial{
      .testimonial-slider{
        .owl-nav{
          top: unset;
          bottom: -40px;
          right: 50%;
          transform: translateX(50%);
        }
      }
    }

  }
  footer {
    &.footer2 {
      &.agency {
        .brand-container {
          padding: 15px;
          margin-top: 30px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .wedding {
    &.header {
      .center-text {
        height: calc(650px - 65px);
        margin-top: 65px;
      }
      .wedding-content {
        .girl {
          left: 0;
          img {
            width: 520px;
          }
        }
        .logo-h1 {
          height: 120px;
          margin-left: 0;
        }
      }
    }
    .event-content {
      &.wedding-content {
        margin-bottom: 20px;
      }
    }
    &.blog {
      .owl-theme {
        .owl-dots {
          margin-top: 30px !important;
        }
      }
    }
    .title {
      .title-img {
        height: 25px;
      }
    }
  }
  .yoga {
    &.header {
      .yoga-content {
        .right-way {
          margin-top: 0;
        }
      }
      .header-text, .header-sub-text {
        margin-bottom: 16px;
      }
      .girl {
        display: none;
      }
      .text-right {
        text-align: center !important;
      }
      .header-sub-text {
        p {
          padding: 0 120px;
          line-height: 25px;
          letter-spacing: 1px;
        }
      }
    }
    .meter {
      margin-top: 25px;
      span {
        height: 32px;
      }
    }
    &.format {
      .format-head-text {
        margin-bottom: 15px;
        .about-font-header {
          font-size: 235%;
        }
      }
      .format-sub-text {
        margin-bottom: 25px;
        .about-para {
          margin-bottom: 25px;
        }
      }
      .girl-img-container {
        min-height: unset;
        .girl-yoga {
          width: 400px;
        }
      }
    }
    &.testimonial {
      .testimonial-info {
        .testimonial-para {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
    &.event {
      .event-container {
        margin-bottom: 50px;
        .circle {
          padding: 12px;
        }
        .event-info {
          margin-left: 20px;
          .address, .address {
            margin-bottom: 10px;
          }
        }
      }
    }
    &.format {
      .experience-container {
        min-height: 360px;
        .exp-img {
          width: 500px;
        }
      }
    }
  }
  .resume {
    .title {
      &.title2 {
        .title-text {
          line-height: 1.4;
        }
      }
    }
    &.about {
      .border-container {
        margin: 5px 0;
      }
    }
    &.services {
      .p-l-0 {
        padding-left: 15px;
      }
    }
  }
  .saas1 {
    &.service {
      .about-chat {
        .chat-box {
          img {
            height: 60px;
          }
        }
      }
    }
    &.build-bg {
      .build-right-slider {
        display: none;
      }
      .build-box {
        .build-head {
          margin-bottom: 30px;
        }
        ul {
          margin-top: 25px;
          li {
            margin-bottom: 12px;
            font-size: 14px;
            line-height: normal;
          }
        }
      }
      .sync1 {
        .item {
          margin: 0;
        }
      }
    }
    &.howitwork {
      .work-tab {
        .nav-pills {
          .nav-link {
            padding: 0 25px;
            img {
              height: 35px;
            }
          }
        }
      }
    }
    &.pricing {
      .btn-default {
        padding: 11px 35px;
      }
      .pricing__switcher {
        margin: 0 auto 70px auto;
      }
      .plan-box {
        .price-box {
          padding: 20px;
          h4 {
            &.no-weight {
              margin: 15px 0 10px 0;
            }
          }
          ul {
            margin-top: 25px;
            margin-bottom: 30px;
            li {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    &.testimonial {
      .video-box {
        margin-right: 0;
        margin-bottom: 30px;
        img {
          position: relative;
          padding: 150px;
          justify-content: center;
          display: flex;
        }
      }
      .testimonial-slider {
        margin-top: 20px;
        .owl-theme {
          .owl-dots {
            display: none;
          }
        }
      }
    }
    &.subscribe {
      .media {
        display: block;
        img {
          height: 50px;
          display: flex;
          margin: 0 auto;
          margin-bottom: 10px;
        }
        .media-body {
          text-align: center;
        }
      }
      .subscribe-input {
        margin-top: 30px;
        form, .text {
          justify-content: center;
        }
      }
      h6 {
        margin-top: 5px;

      }
    }
  }
  .saas2 {
    .set-center-div {
      min-height: unset;
      .side-img {
        display: none;
      }
    }
    &.laptop-slider {
      .slider-container {
        .center-img {
          img {
            height: 246px;
            width: 442px;
          }
        }
      }
    }
    &.feature {
      margin-bottom: -30px;
      .center-content {
        margin-bottom: 30px;
        img {
          height: 30px;
        }
        .feature-content {
          margin-left: 15px;
        }
      }
    }
    &.services {
      .saas2-services {
        .row {
          .service-container {
            &:nth-child(n+4) {
              margin-top: 30px;
            }
          }
        }
      }
    }

  }
  .copyright {
    .link-horizontal {
      ul {
        li {
          padding-right: 10px;
        }
      }
    }
    .copyright-text {
      &.text-right {
        text-align: center !important;
      }
    }
  }
  .filter-main-btn {
    display: block;
  }
  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 999;
    overflow-y: scroll;
    padding: 0 0 15px 15px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px $border-white;
    .collection-sidebar-banner {
      text-align: center;
    }
    .theme-card {
      padding-left: 30px;
      padding-right: 30px;
    }
    .collection-sidebar-banner {
      padding: 0 30px;
    }
  }
  .collection-filter-block {
    border: none;
  }
  .collection-mobile-back {
    display: block;
  }
  .dropdown-sec {
    .dropdown-content {
      .dropdown-main-container {
        padding: 0;
      }
      &.dropdown-size {
        min-width: unset;
      }
    }
    .menu-head {
      font-size: 13px;
      color: black;
      border-bottom: 1px solid $lighten-white2;
      background: $lighten-white !important;
      margin-bottom: 0;
    }
    .drop-down-design {
      box-shadow: none;
    }
    ul {
      li {
        -webkit-transition: 0.6s;
        transition: 0.6s;
        background: $white6;
        border-bottom: 1px solid $lighten-white;
      }
    }
  }

  //-- costome scss --//
  .mega-menu-container{
    .container{
      margin: 0;
    }
  }
  .loding-header{
    padding: 10px 0;
  }
  footer{
    &.app2{
      section{
        .container{
          .row{
            div:nth-child(3) , div:nth-child(4){
              margin-top: 30px;
              h5{
                padding-bottom: 25px;
              }
            }
          }
        }
      }
    }
    &.saas1{
      h5{
        padding-bottom: 40px;
      }
    }
  }
  .p-b-160{
    padding-bottom: 120px;
  }
  .sub-menu-title{
    font-weight: 500;
  }
  // inner pages //
  .login-page {
    .authentication-right {
      height: auto;
    }
  }
  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 0 30px;
          height: 100%;
          align-self: center;
          p {
            margin-bottom: 0;
          }
        }
      }
      .slick-track {
        .slick-slide {
          &:nth-child(even) {
            .media {
              border-left: none;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .blog-detail-page {
    .blog-advance {
      ul {
        margin-bottom: 20px;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .top-banner-content {
    h4 {
      font-size: 20px;
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      border-top: none;
      .product-filter-content {
        .search-count {
          border-top: 1px solid $round-border;
          text-align: left;
          padding: 21px 34px 21px 34px;
        }
        .collection-view {
          display: none;
        }
        .collection-grid-view {
          display: none !important;
        }
        .product-page-per-view, .product-page-filter {
          width: 100% !important;
          &:before {
            right:15px !important;
          }
          select {
            border-left: 1px solid $round-border;
            padding: 21px 34px 21px 34px;
            border-top: none;
            border-bottom: none;
          }
        }
        .product-page-per-view {
          select {
            border-right: none !important;
          }
        }
      }
      .popup-filter {
        .product-page-per-view, .product-page-filter {
          border-top:1px solid $round-border;
        }
      }
    }
  }
  .filter-main-btn {
    display: block;
  }
  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 9999;
    overflow-y: scroll;
    padding: 0 0 15px 15px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px #ddd;
    .collection-sidebar-banner {
      text-align: center;
    }
    .theme-card {
      padding-left: 30px;
      padding-right: 30px;
    }
    .collection-sidebar-banner {
      padding: 0 30px;
    }
  }
  .collection-filter-block {
    border:none;
  }
  .collection-mobile-back {
    display: block;
  }
  .collection {
    .section-t-space {
      padding-top: 30px;
    }
    .partition-collection {
      > div {
        &:nth-last-child(1) {
          margin-top: 30px;
        }
        &:nth-last-child(2) {
          margin-top: 30px;
        }
      }
    }
  }
  .right-login {
    margin-top: 30px;
  }
  .contact-page {
    .map {
      iframe {
        height: 350px;
      }
    }
    .contact-right {
      padding-bottom: 0;
      ul {
        li {
          padding-left: 0;
          border-bottom: 1px solid $round-border;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 15px;
          .contact-icon {
            position: relative;
            margin: 0 auto;
            border-right: 0;
          }
          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-table {
      thead {
        th {
          &:last-child {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 135px;
          &:last-child {
            display: none;
          }
        }
      }
    }
    .cart-buttons {
      > div {
        &:last-child {
          padding-right:15px;
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 0;
        }
      }
    }
  }
  .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 138px
        }
      }
    }
  }
  .product-right {
    h2 {
      margin-top: 15px;
    }
    .payment-card-bottom {
      .d-flex {
        justify-content: center;
      }
    }
  }
  .product-right {
    text-align: center;
    margin: 20px 0 10px 0;
    .detail-section, .product-icon {
      justify-content: center;
    }
    .product-description {
      .qty-box {
        justify-content: center;
      }
    }
    .timer {
      text-align: left;
    }
    .product-icon {
      .product-social {
        li {
          padding-right: 20px;
        }
      }
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 55%;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
          margin-bottom: 20px;
          background: none $theme-deafult;
          .open-popup {
            text-align: left;
          }
          > a {
            color: $white;
          }
          .collection-filter {
            height: 100vh;
            left: -350px;
            transition: all 0.5s ease;
          }
        }
        .search-count {
          width: 100%;
          padding: 10px 0;
          text-align: center;
        }
        .collection-view, .collection-grid-view {
          display: none;
        }
        .product-page-per-view, .product-page-filter {
          width: 50%;
          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          &:before {
            top:13px;
          }
        }
        .product-page-filter {
          &:before {
            left:unset;
            right: 35px;
          }
        }
      }
    }
  }
  .tab-product {
    padding-top: 0;
  }
  .tab-product {
    .nav-material {
      &.nav-tabs {
        justify-content: center;
      }
    }
  }
  .pro_sticky_info {
    border: none;
    padding: 10px 0;
  }
  .product-form-box {
    margin-bottom: 10px;
  }
  .dashboard-left {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 $round-border;
    .block-title {
      h2 {
        display: none;
      }
    }
    .block-content {
      border:none;
      padding: 0;
      margin-top: 20px;
      ul {
        li {
          &:hover {
            padding-left: 0;
          }
        }
      }
    }
  }
  .account-sidebar {
    display: block;
  }
  .typography_section {
    .row {
      > div {
        &:first-child {
          .typography-box {
            &:last-child {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .product-slick, .rtl-product-slick,  .product-right-slick, .rtl-product-right-slick {
    .slick-prev, .slick-next {
      opacity: 1;
    }
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
    }
  }
  .order-up {
    order: -1;
  }
  .bundle {
    .bundle_img {
      justify-content: center;
    }
    .bundle_detail {
      .theme_checkbox {
        padding: 0 20%;
      }
    }
  }
  .checkout-page {
    .checkout-form {
      .checkout-details {
        margin-top: 30px;
      }
    }
  }
  .custom-scroll{
    &.header-absolute {
      &.loding-header{
        .responsive-btn{
          a{
            i{
              color: $white !important;
            }
          }
        }
      }
    }
  }
  .portfolio-metro{
    h1{
      font-size: 35px;
      margin-bottom: 0;
    }
  }
  .product-title {
    &.size-text {
      text-align: left;
    }
  }
  .image-swatch {
    justify-content: center;
  }

  // breadcrumb  css//
  .breadcrumb-section-main {
    &.inner-2 {
      .breadcrumb-contain {
        >div {
          width: 40%;
          &:last-child {
            width: 60%;
          }
        }
      }
    }
  }
  .opensubmenu{
    display: block !important;
  }

  .openSubChildMenu{
    display: block !important;
  }
  .overlay-sidebar{
    visibility: hidden;
    opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1s;
  &.overlay-sidebar-open{
    opacity: 0.8;
    visibility: visible;
  }
  }
  .overlay-sidebar-header{
    visibility: hidden;
    opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1s;
  &.overlay-sidebar-header-open{
    opacity: 0.8;
    visibility: visible;
  }
  }
}

@media only screen and (min-width: 768px) {
  .blog-sec {
    &.blog-list {
      .blog-agency {
        .blog-contain {
          .blog-info {
            padding-top: 0;
          }
          .blog-img {
            border: 1px solid $border-blog;
          }
        }
        .blog-info {
          padding: 0 !important;
        }
        .btn-bottom {
          margin-top: 18px;
          .btn-default {
            padding-left: 28px;
            margin: unset;
            transform: unset;
          }
        }
      }
      .img-container {
        padding: 15px 30px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .product-box,  .product-wrap{
    &:hover {
      .cart-wrap {
        button {
          animation: none !important;
        }
        a {
          i {
            animation: none
          }
        }
      }
    }
  }
  .p-3col-width {
    .p-width {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 12px;
    }
  }
  .p-4col-width {
    .p-width {
      max-width: 50%;
      flex: 50%;
    }
  }
  .m-layout {
    .m-width {
      &.m-3-col, &.m-4-col {
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
        margin-bottom: 0;
      }
    }
  }
  header.nav-lg {
    padding: 0.2rem 1rem;
  }
  .header {
    .bg {
      height: auto;
    }
  }
  .ecommerce-footer {
    .footer-end{
      text-align: center;
    }
    .sub-footer {
      margin-top: 40px;
    }
    .payment-card-bottom{
      text-align: center;
      margin-top: 10px;
    }
    .footer-theme{
      > .col{
        flex-basis: auto;
        width: 100%;
      }
    }
    h4{
      margin-bottom: 0;
    }
    .footer-mobile-title{
      display: block;
      padding-top: 0;
    }

    .col-md-12{
      text-align: left;
      margin-bottom: 0;
    }
  }
  .banner-three{
    >div{
      &+div{
        margin-top: 30px;
      }
    }
  }
  .portfolio-section{
    .col-12{
      &.m-b-50{
        margin-bottom: 0!important;
      }
    }
  }
  .team-grid{
    .speker-container{
      .d-flex{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
    .employee{
      h5,h6{
        width: 100%;
      }
      p{
        text-align: center;
      }
    }
  }
  .animated-bg i{
    height: 40px;
    width: 40px;
  }
  .event.team-grid {
    .team-para{
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .saas2.pricing {
    h6 {
      line-height: 1.8;
    }
  }
  .pricing.yoga .owl-theme .owl-dots, .pricing.saas2 .owl-theme .owl-dots {
    margin-top: 20px !important;
  }
  .saas1.faq .faq-block h6.link {
    margin-top: 20px;
  }
  .music{
    .link-horizontal{
      padding-bottom: 10px;
    }
  }
  .saas1.footer2{
    padding-bottom: 10px;
  }
  .m-b-25{
    margin-bottom: 15px;
  }
  footer .footer-title.mobile-title h3 {
    line-height: 1.4;
  }
  .event{
    &.contact {
      .iframe-container ,.bg-white{
        border-radius: 15px;
      }
    }
  }
  .portfolio-section {
    &.portfolio-creative {
      .my-auto {
        padding: 20px !important;
      }
      &.creative2 {
        .set-order {
          order: 2;
        }
      }
    }
  }
  .portfolio-metro {
    h1 {
      letter-spacing: 0;
      margin-bottom: 20px;
    }
  }
  .coming-soon {
    .container-fluid {
      padding: 0 15px !important;
    }
    .set-bg-img {
      position: absolute;
      height: 100%;

      .bg-coming-soon {
        height: 100%;
      }
    }
    .bg-black {
      background: rgba(0, 0, 0, 0.67);
    }
  }
  .blog {
    .img-container {
      .blog-info {
        text-align: center;
        .social-list {
          ul {
            justify-content: center;
          }
        }
      }
    }
  }
  .wedding {
    &.format {
      .format-head-text {
        margin-bottom: 10px;
      }
      .format-sub-text {
        .about-para {
          margin-bottom: 10px;
        }
      }
    }
    &.copyright {
      border-top-left-radius: 60px;
      border-top-right-radius: 60px;
    }
  }
  .yoga, .app1 {
    .subscribe {
      .d-flex {
        display: block !important;
        .form-control {
          padding: 17px 35px;
        }
      }
    }
  }
  .copyright.resume hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  header.wedding {
    border-radius: 0;
  }
  .wedding {
    .subscribe {
      .d-flex {
        display: block !important;
        .btn-default {
          margin: 0 auto;
        }
      }
    }
  }
  .center-text {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .saas1 {
    &.pricing {
      .plan-box {
        .price-box {
          padding: 40px;
        }
      }
    }
  }
  .subscribe {
    .form-group {
      input {
        margin-bottom: 15px;
      }
    }
  }
  .filter-section {
    .filter-container {
      ul.filter {
        > li {
          text-decoration: line-through;
          padding: 0 8px;
          a {
            line-height: 1;
          }
          &.active {
            background-color: transparent;
            text-decoration: none;
            a {
              color: $black;
            }
          }
        }
      }
    }
  }
  .testimonial {
    &.gym, &.music, &.wedding, &.yoga, &.agency {
      .testimonial-para {
        margin-bottom: 20px !important;
      }
      .testimonial-info {
        margin-top: 30px !important;
        padding: 0 10px !important;
      }
    }
  }
  footer.footer2.saas2 {
    margin-top: 0;
  }
  .event {
    &.subscribe {
      .form-control {
        margin-bottom: 30px;
      }
    }
    &.about {
      .text-right {
        width: 50%;
        margin: 0 auto;
      }
    }
    &.contact {
      .p-r-0, .p-l-0 {
        padding: 0 !important;
      }
      .map {
        margin-bottom: 20px;
      }
    }
  }
  .subscribe {
    &.yoga{
      .button-primary{
        right: unset;
      }
    }
    .subscribe-2 {
      padding-top: 30px;
    }
  }
  .app2 {
    .subscribe {
      .subscribe{
        margin-top: 30px;
      }
      .form-group {
        .button-primary {
          .btn {
            border: 1px solid $white;
            padding: 10px 20px;
            margin-top: 20px;
          }
        }
        .icon {
          top: 35px;
        }
      }
    }
    &.brand-sliders{
      padding-bottom: 60px;
    }
  }

  //inner pages start
  .agency {
    &.blog {
      .blog-agency {
        .blog-contain {
          .img-container {
            .blog-info {
              text-align: center;
              padding: 15px;
              > .d-flex {
                display: block !important;
                > div {
                  margin-bottom: 5px;
                }
              }
              .m-b-20 {
                margin-bottom: 10px !important;
              }
              p {
                letter-spacing: 0;
              }
            }
            .blog-head {
              margin-bottom: 16px;
            }
            .m-t-20 {
              margin-top: 10px !important;
            }
          }
        }
      }
      &.blog-split {
        .blog-list {
          &:nth-child(even) {
            .center-text {
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .videos .video {
    padding: 85px 0;
  }
  .services {
    &.app1 {
      .row {
        .col-md-4 {
          + .col-md-4 {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .subscribe {
    &.app1{
      .button-primary{
        right: unset;
      }
    }
    .button-primary {
      position: relative;
      button {
        margin: 0 auto;
      }
    }
  }
  .subscribe {
    .form-group {
      input {
        padding: 24px 35px;
      }
    }
  }
  .tap-top {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
    line-height: 1.7;
  }
  .download-img, .link-horizontal ul {
    align-items: center;
    justify-content: center;
  }
  .download {
    .download-img {
      .center-img-content {
        margin-left: 0 !important;
      }
    }
  }
  .agency {
    &.blog {
      .blog-agency {
        .blog-contain {
          flex-flow: column;
          .img-container {
            order: 2;
          }
        }
      }
    }
  }
  .title {
    .main-title {
      margin-bottom: 8px;
      h2 {
        margin-bottom: 0;
        line-height: 1.4 !important;
      }
    }
  }
  .accordion {
    .register-page {
      margin-top: 25px;
    }
    .testimonial {
      &.videos {
        margin-top: 25px;
      }
    }
  }
  .portfolio-section {
    &.videos {
      .m-b-50 {
        margin-bottom: 10px;
      }
      .w-100 {
        width: 100% !important;
      }
    }
    .portfolio-detail {
      margin-top: 20px;
    }
  }
  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          a {
            justify-content: center;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n+4) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n+4) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }
  .wishlist-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n+3) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n+3) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }
  //inner pages end
  .app1 {
    &.services {
      .container {
        margin-bottom: -20px;
      }
      .service {
        margin-bottom: 20px;
      }
    }
  }
  .login-modal {
    .close {
      right: 16px;
    }
  }
  .single-product-tables table {
    width: 100%;
  }
  .format {
    text-align: center;
    .link-horizontal {
      ul {
        justify-content: center;
      }
    }
  }
  .icon-collection {
    justify-content: center;
  }
  .header {
    .rotate {
      display: none;
    }
    &.saas2 {
      .center-text {
        padding: 110px 0;
      }
    }
    .center-text {
      text-align: center;
      height: auto;
      margin-top: 60px;
      padding: 60px 0;
    }
    &.wedding {
      .center-text {
        height: auto;
      }
    }
    .music-content {
      .center-text {
        height: calc(503px - 58px);
        margin-top: 58px;
      }
    }
    .img-mobile {
      display: none;
    }
    .wave-orange {
      display: none;
    }
    &.app1 {
      .link-horizontal {
        ul {
          justify-content: center;
        }
      }
    }
    .bg {
      &.app2-header {
        .header-text {
          h1 {
            &:before {
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
        .link-horizontal {
          ul {
            justify-content: center;
          }
        }
      }
    }
    .header-text {
      margin-bottom: 25px;
    }
    .header-text {
      margin-bottom: 30px;
    }
    .header8-content {
      .slider-banner {
        display: none;
      }
      .center-text {
        & > div {
          width: 100%;
          .header-text {
            .d-flex {
              justify-content: center;
            }
            .slider-logo {
              margin-left: 5px;
              img {
                height: 15px;
              }
            }
            .header-text {
              margin-bottom: 12px;
            }
            .header-sub-text {
              margin-bottom: 12px;
              span {
                font-size: 20px;
              }
            }
          }
          .link-horizontal {
            ul {
              justify-content: center;
            }
          }
        }
      }
    }
    &.saas1 {
      h1 {
        margin-top: -10px;
      }
      .bg {
        background-position: right;
      }
    }
    &.event {
      .set-bottom {
        bottom: 5px;
      }
    }
  }
  .borders-before {
    margin-bottom: 20px;
  }
  .pricing {
    .siema {
      margin: -5px;
      .price-container {
        &.price-margin {
          margin: 5px 15px;
        }
      }
    }
  }
  .link-horizontal {
    ul {
      li {
        padding-right: 15px;
        &.borders-right {
          margin-right: 15px;
          height: 40px;
        }
        a {
          &.icon-btn {
            i {
              font-size: 15px;
              height: 40px;
              width: 40px;
              margin: 0 auto 10px;
            }
          }
        }
      }
    }
  }
  .videos {
    .video {
      margin-bottom: 20px;
    }
    .counters {
      text-align: center;
      .link-horizontal {
        ul {
          justify-content: center;
        }
      }
    }
    .w-100 {
      width: 70% !important;
    }
  }
  .download {
    .center-mobile {
      margin-top: -90px;
      margin-bottom: -80px;
      min-height: 484px;
      img {
        max-height: 400px;
      }
    }
  }
  .services {
    .service {
      &.app2 {
        padding: 30px 10px;
      }
      .service-feature {
        .feature-text {
          margin-bottom: 20px;
        }

      }
    }
    &.app2 {
      .service-container {
        .service {
          margin-bottom: 30px;
        }
        &:nth-child(n+3) {
          .service {
            margin-bottom: 30px;
          }
        }
        &:nth-child(n+5) {
          .service {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .saas1 {
    .app1 p {
      line-height: 22px;
    }
  }
  .download {
    .download-img {
      .center-abs {
        &.elipse {
          display: none;
        }
      }
      .download-icon {
        display: none;
      }
    }
  }
  .m-t-50 {
    margin-top: 30px;
  }
  .about {
    &.about2 {
      .counters {
        &.set-height {
          text-align: center;
          img {
            height: 500px;
          }
        }
      }
    }
  }
  .event, .music {
    &.booking {
      .form-inline {
        display: block;
        .form-group {
          margin-bottom: 15px;
        }

      }
      .form-group {
        input {
        }
      }
      .btn-default {
        padding: 15px 40px;
      }
    }
  }
  .title {
    margin-bottom: 20px;
    &.title2 {
      .sub-title {
        line-height: 30px;
      }
      .para {
        font-size: 115%;
      }
    }
  }
  .m-b-40 {
    margin-bottom: 20px;
  }
  .m-t-50 {
    margin-top: 30px;
  }
  .booking-1 {
    .form-inline {
      display: block;
      .form-group {
        margin-bottom: 15px;
      }
    }
  }
  .team-section {
    .teams {
      &:nth-child(even) {
        margin-top: 0
      }
    }
  }
  footer {
    .footer-headings {
      display: none;
    }
    .footer-title {
      &.mobile-title {
        display: block;
      }
    }
    .footer-contant {
      padding: 10px 0;
    }
    .footer-title {
      padding: 10px 0;
      text-align: left;
      border-bottom: 1px solid rgba($white, 0.2);
      position: relative;
      &.active {
        .according-menu {
          font: normal normal normal 15px/1 FontAwesome;
          &:before {
            content: "\f106";
            position: absolute;
            right: 2px;
            bottom: 10px;
          }
        }
      }
      .according-menu {
        font: normal normal normal 15px/1 FontAwesome;
        &:before {
          content: "\f107";
          position: absolute;
          right: 2px;
          bottom: 10px;
        }
      }
    }
    &.footer2 {
      &.saas2 {
        background-image: linear-gradient($dark, $light);
        .footer-padding {
          padding-top: 0;
        }
      }
    }
  }
  .event, .music {
    &.booking {
      .form-inline {
        margin-bottom: 35px;
      }
    }
  }
  .app2 {
    &.format {
      .icon-collection {
        justify-content: right;
      }
      text-align: left;
      .format-head-text {
        .about-font-header {
          font-size: 150%;
        }
      }
    }
    &.about {
      padding-top: 70px;
    }
    &.about {
      .set-height {
        min-height: 445px;
        .mobile1 {
          width: 250px;
        }
      }
    }
    .subscribe {
      .form-group {
        .d-flex {
          margin-top: 50px;
        }
      }
    }
    &.footer2 {
      &.bg {
        background: linear-gradient($light, $dark);
      }
    }
  }
  .event, .wedding, .resume {
    &.gallery {
      .gallery-list {
        margin-bottom: 40px;
      }
      .list {
        padding: 5px 20px;
        a {
          font-size: 14px;
        }
      }
    }
  }
  .event, .saas2 {
    &.testimonial {
      .testimonial {
        .item {
          &.p-t-30 {
            padding-top: 0;
          }
        }
        &.owl-theme {
          .owl-nav {
            margin-top: 30px;
            .owl-prev, .owl-next {
              position: relative;
              margin: 0;
              padding: 0;
              top: unset;
              transform: unset;
            }
            .owl-prev {
              left: unset;
              margin-right: 20px;
            }
            .owl-next {
              right: unset;
            }
          }
        }
      }
      .quotes {
        text-align: center;
        .left-quote, .right-quote {
          display: none;
        }
      }
      .testimonial-msg {
        width: 100%;
        img {
          margin: 0 auto;
        }
        .msg-box {
          display: none;
        }
      }
      .rating {
        .stars {
          margin-right: 10px;
          i {
            font-size: 14px;
          }
        }
      }
    }
  }
  .event {
    &.speaker {
      .container {
        margin-bottom: -20px;
        .speker-container {
          margin-bottom: 30px;
        }
      }
    }
    .format {
      .format-head-text, .format-sub-text {
        margin-bottom: 30px;
        .about-para {
          margin-bottom: 20px;
          line-height: 18px;
        }
      }
    }
    &.about {
      .text-right {
        text-align: center !important;
      }
      .announcer-img {
        margin-bottom: 60px;
      }
    }
    &.sponsor {
      .set-margin {
        margin-bottom: -30px;
      }
      .sponsor-img {
        margin-bottom: 30px;
      }
    }
    &.contact {
      .bg-white {
        form {
          padding: 20px;
          .form-group {
            margin-bottom: 30px;
          }
        }
      }
      .iframe-container {
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.copyright {
      .d-flex {
        justify-content: center;
        margin-bottom: 10px;
      }
    }
  }
  .schedule {
    .event-container {
      padding-right: 20px;

      .cal-time {
        &.events {
          .timing {
            display: flex;
            float: right;
            padding-top: 35px;
            .event-time {
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
              padding: 0 20px;
            }
            .event-time {
              &.active {
                padding: 0 20px;
              }
            }
            .border-container {
              display: flex;
              align-self: center;
              .border-dashed {
                width: 50px;
                height: unset;
                border-left: unset;
                border-top: 2px dashed;
              }
            }
          }
        }
      }
    }
  }
  .gym {
    .about-img {
      margin-bottom: 60px;
      .borders-around {
        .bg-black {
          width: 38%;
          &:after, &:before {
            width: 184px;
            height: 184px;
          }
        }
        .format-img {
          width: 70%;
        }
      }
    }
    .calculate-bmi {
      margin-top: 20px;
    }
   .default-dots {
        .owl-dots {
          bottom: 25px !important;
        }
    }
    .counter-container {
      & + .counter-container {
        border-left: 1px solid #ddd;
        padding-bottom: unset;
        & + .counter-container {
          padding-top: unset;
          margin-top: 30px;
          border-top: 0;
        }
      }
    }
    .calculate-bmi {
      tr {
        td {
          font-size: 14px;
          padding: 15px 0;
        }
      }
    }
  }
  .music {
    .img-height {
      img {
        width: 500px;
      }
    }
    .left-side, .right-side{
      display: none;
    }
    div {
      &:nth-child(n+5) {
        .album-list {
          margin-bottom: 30px;
        }
      }
      &:nth-child(n+7) {
        .album-list {
          margin-bottom: 0;
        }
      }
    }
    &.artist {
      .artist-text {
        p {
          &.text-para {
            font-size: 115%;
          }
        }
      }
    }
    &.sponsor {
      div {
        .sponsor-img {
          margin-bottom: 30px;
        }
        &:nth-child(n+11) {
          .sponsor-img {
            margin-bottom: 0;
          }
        }
      }
    }
    &.copyright {
      .link-horizontal {
        ul {
          justify-content: center;
        }
      }
    }
    &.bg-video {
      .center-text {
        .d-flex {
          justify-content: center;
        }
      }
    }
  }
  .agency {
    &.header {
      .bg {
        background: $primary;
      }
      h1 {
        color: $white;
        margin-top: -10px;
      }
      p {
        color: $white;
      }
      .center-text {
        text-align: center;
        margin-left: 0;
      }
      .link-horizontal {
        ul {
          justify-content: center;
        }
      }
      .girl-boy {
        img {
          display: none;
        }
      }
      .plus-container {
        display: none;
      }
    }
    .format-container {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      .block-span {
        display: unset;
      }
      .borders-before {
        margin-bottom: 15px;
      }
      .format-head-text {
        margin-bottom: 25px;
      }
      .format-sub-text {
        margin-bottom: 20px;
      }
    }
    &.testimonial {
      .testimonial-container {
        &.testimonial-container2 {
          padding: 20px;
        }
      }
      .testimonial-slider {
        .owl-nav {
          position: relative;
          line-height: 0;
          top: unset;
          text-align: center;
          bottom: unset;
          .owl-prev, .owl-next {
            padding: 0;
            margin: 0;
          }
          .owl-prev {
            margin-right: 20px;
          }
        }
      }
    }
    &.agency-content {
      text-align: center;
      .agency-header-center-container {
        justify-content: center;
        .borders {
          display: none;
        }
      }
      .agency-para, .btn-default {
        margin-left: 0;
      }
      .side-img {
        display: none;
      }
      .blue-img {
        display: none;
      }
      .agency-head {
        .agency-head-text {
          font-size: 245%;
        }
      }
      .agency-para {
        line-height: 26px;
      }
    }
    &.testimonial-bg {
      background: $primary;
    }
    &.speaker {
      .team-img {
        .social {
          ul {
            li {
              margin-right: 10px;
              a {
                i {
                  height: 25px;
                  width: 25px;
                }
              }
            }
          }
        }
      }
    }
    &.video {
      .d-inline-block {
        .bg-video {
          width: 70%;
        }
      }
    }
  }
  .wedding {
    &.format {
      .format-small-text {
        margin-top: 20px;
      }
      .about-img {
        width: 400px;
      }
    }
    &.header {
      .header-text {
        margin-bottom: 22px;
      }
      .wedding-content {
        .logo-h1 {
          height: 90px;
          margin-left: 0;
        }
      }
    }
    .wedding-banner {
      .frem {
        width: 85%;
      }
      .text-container {
        .couple-text {
          margin-bottom: 0;
          .banner-text {
            font-size: 45px;
          }
          .banner-text {
            &.small {
              font-size: 35px;
            }
          }
        }
        .place {
          font-size: 25px;
        }
      }
    }
    .wedding-time {
      margin-bottom: 30px;
    }
    &.blog {
      .blog-container {
        .set-skew {
          clip-path: unset;
          margin-bottom: 20px;
        }
        .blog-date, .blog-place, .blog-para {
          margin-left: 0;
        }
        .blog-para {
          text-indent: unset;
        }
        .btn {
          &.btn-blog {
            margin-left: 0;
          }
        }
      }
    }
    &.pricing {
      .when-slider {
        &.owl-theme {
          .owl-nav {
            .owl-prev, .owl-next {
              display: none;
            }
          }
        }
      }
    }
  }
  .yoga {
    &.header {
      .yoga-content {
        .owl-nav {
          .owl-prev, .owl-next {
            padding: 6px 14px;
          }
          .owl-prev {
            left: 0;
          }
          .owl-next {
            right: 0;
          }
        }
      }
      .header-sub-text {
        p {
          padding: 0 40px;
        }
      }
    }
    .set-order-2 {
      order: 2;
    }
    &.testimonial {
      .testimonial-info {
        padding: 0;
        margin-top: 30px;
      }
    }
    &.event {
      .event-container {
        margin-bottom: 30px;
        .event-info {
          .time, .address {
            margin-bottom: 5px;
          }
        }
      }
      .row {
        div {
          &:nth-child(n+5) {
            .event-container {
              margin-bottom: 30px;
            }
          }
          &:last-child {
            .event-container {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.format {
      .experience-container {
        min-height: unset;
        .exp-img {
          position: relative;
        }
      }
      .girl-img-container {
        .girl-yoga {
          position: relative;
          display: flex;
        }
      }
    }
  }
  .resume {
    &.header {
      .link-horizontal {
        ul {
          justify-content: center;
        }
      }
      .font-large {
        font-size: 120%;
      }
    }
    .bg-pink {
      img {
        display: none;
      }
    }
  }
  .saas1 {
    .work-tab-bg {
      padding-top: 30px;
    }
    .title {
      img {
        height: 18px;
        margin-right: 5px;
      }
    }
    &.howitwork {
      .work-tab {
        .nav-pills {
          .nav-link {
            padding: 0 15px;
            img {
              display: none;
            }
            h6 {
              font-size: 16px;
              margin-top: 10px;
            }
            &.active {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
    &.build-bg {
      .owl-carousel {
        .owl-item {
          .work-slide {
            height: 400px;
          }
        }
      }
    }
    &.faq {
      .faq-img-block {
        display: none;
      }
      .faq-block {
        .frequent-text {
          margin-top: -8px;
        }
      }
    }
  }
  .saas2 {
    &.feature {
      .advance-feature-img {
        margin-bottom: 30px;
      }
      .center-content {
        display: block;
        text-align: center;
        img {
          margin-bottom: 15px;
        }
        .feature-content {
          margin-left: 0;
        }
      }
    }
    &.feature {
      .image-container {
        .advance-feature-img {
          margin-bottom: 50px;
        }
      }
    }

    &.services {
      .saas2-services {
        .row {
          .service-container {
            &:nth-child(n+3) {
              margin-top: 30px;
            }
          }
        }
      }
      .service {
        .img-block {
          max-width: 70px;
          margin: 0 auto;
          margin-bottom: 15px;
        }
      }
    }
    &.laptop-slider {
      .slider-container {
        .center-img {
          img {
            height: auto;
            width: 100%;
          }
        }
        .trainers-slider{
          &.owl-carousel{
            .owl-item{
              &.active{
                &.center{
                  .item:after, .item:before{
                    display: none;
                  }
                  img{
                    margin: 0 auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.testimonial {
      .container {
        margin-bottom: 0;
      }
    }
  }
  footer {
    &.footer2 {
      &.agency {
        .link-horizontal {
          margin-bottom: 20px;
        }
        .link-horizontal, .list {
          float: unset !important;
          ul {
            justify-content: center;
            li {
              &.borders-right {
                height: 30px;
              }
            }
          }
        }
      }
    }
    &.app2{
      section{
        .container{
          .row{
            > div {
              &:nth-child(3),
              &:nth-child(4) {
                margin-top: unset;
              }
            }
          }
        }
      }
    }
  }
  .testimonial {
    &.music {
      .testimonial-info {
        .testimonial-para {
          margin-bottom: 20px;
          line-height: 28px;
          font-size: 120%;
        }
      }
      .music-testimonial-slider {
        &.owl-theme {
          .owl-nav {
            .owl-prev {
              left: -8px;
            }
            .owl-next {
              right: -32px;
            }
          }
        }
      }
    }
  }
  .counter {
    .counters {
      .counter-img {
        margin-bottom: 15px;
      }
    }
  }
  .blog {
    .img-container {
      .blog-info {
        padding: 15px;
        .blog-text {
          font-size: 145%;
        }
        .small-font {
          margin-bottom: 10px;
        }
      }
    }
  }
  .event, .gym, .agency, .resume {
    &.pricing {
      .btn-default {
        padding: 12px 25px;
      }
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter, .product-filter-content {

        .collection-view, .collection-grid-view {
          display: none;
        }
        .product-page-per-view {
          width: 50%;
        }
        .product-page-filter {
          width: 50%;
        }
      }
    }
  }
  .product-pagination {
    .pagination {
      justify-content: center;
      border-left: 1px solid $border-white;
    }
    .pagination {
      border-color: $primary;
    }
  }
  .collection-product-wrapper {
    .pagination_sec {
      margin: 0 auto !important;
    }
    .product-pagination {
      .product-search-count-bottom {
        h5 {
          padding: 12px 0;
          text-align: center;
          width: 100%;
        }
      }
      .theme-paggination-block {
        nav {
          border-bottom: 1px solid;
          border-right: 1px solid;
          border-left: 1px solid;
          border-color: $border-white;
          display: flex;
        }
      }
    }
  }
  .agency{
    header{
      nav{
        padding: 15px 0;
        .responsive-btn{
          i{
            color: #000!important;
          }
        }
      }
    }
  }
  .counter-container {
    & + .counter-container {
      padding-bottom: 30px;
      border-left: 1px solid #ddd;
      & + .counter-container {
        margin-top: 0 !important;
        border-left: 0;
        padding-top: 30px;
        border-top: 1px solid #ddd;
        & + .counter-container {
          border-left: 1px solid #ddd;
        }
      }
    }
  }
  .p-b-160{
    padding-bottom: 145px;
  }
  header{
    &.agency{
      nav{
        a{
          img{
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }


  // inner pages //
  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .product-pagination {
    .pagination {
      justify-content: center;
      border-left: 1px solid $round-border;
    }
  }
  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 0;
        }
      }
    }
  }
  .about-page {
    text-align: center;
    p {
      line-height: 1.5;
    }
  }
  .team {
    h2 {
      font-size: 25px;
    }
  }
  .layout2-logo {
    padding-right: 70px;
  }
  .faq-section {
    .theme-accordion {
      .card {
        .card-body {
          p {
            line-height: 1.5 !important;
          }
        }
        .card-header {
          button {
            font-size: 14px !important;
            white-space: normal;
            width: 100%;
            padding: 16px 30px 16px 18px!important;
          }
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        display: block;
        border: 1px solid #eee;
        padding: 15px;
        .img-wrapper, .img-block {
          margin: 0 auto 15px;
        }
        .product-detail {
          padding-left: 0;
          text-align: center !important;
        }
      }
    }
  }
  .register-page {
    .theme-card {
      padding: 25px;
      .theme-form {
        input {
          margin-bottom: 25px;
          padding: 13px 18px;
        }
      }
    }
  }
  .collection {
    .partition-collection {
      > div {
        &:nth-last-child(1),  &:nth-last-child(2), &:nth-last-child(3){
          margin-top: 30px;
        }
      }
    }
  }
  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 15px 18px;
          }
        }
      }
      .product-search-count-bottom {
        justify-content: center;
        padding-right: 0;
        h5 {
          padding: 10px 0;
        }
      }
      .theme-paggination-block {
        nav {
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          a {
            justify-content: center;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n+4) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n+4) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }
  .wishlist-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n+3) {
            display: none;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(-n+3) {
            display: none;
          }
          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }
  .search-product {
    > div {
      &:nth-last-child(4) {
        margin-top: 30px;
      }
    }
  }
  .blog-page {
    .order-sec {
      order: -1;
    }
    .blog-media {
      &:last-child {
        margin-bottom: 30px;
      }
    }
    .blog-sidebar {
      ul {
        li {
          display: block;
        }
      }
    }
  }
  .tab-pane {
    iframe {
      width: 100%;
    }
  }
  .product-order {
    .product-order-detail {
      img {
        height: auto;
      }
    }
  }
  .typography_section {
    .typography-box {
      .typo-content {
        &.product-pagination {
          .pagination {
            border-left: none;
          }
        }
        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .breadcrumb-section{
    padding: 150px 0;
    text-align: center;
    h2{
      line-height: normal;
    }
    &.video-sec{
      height: 40vh;
    }
  }
  .agency{
    &.blog{
      &.blog-sec{
        &.blog-list , &.blog-split{
          .blog-img{
            width: 100%;
          }
        }
      }
    }
  }
  .product-box{
    .cart-info, .cart-wrap{
      opacity: 1;
    }
  }
  .portfolio-metro{
    h1{
      font-size: 30px;
    }
  }
  .portfolio-section {
    &.metro-section  {
      .product-box {
        .cart-wrap {
          a {
            i {
              opacity: 1;
              animation: none !important;
            }
          }
        }
        .product-detail {
          padding: 4px;
          opacity: 1;
          bottom: 15px;
          h6 {
            padding-bottom: 0;
          }
        }
      }
    }
  }


  // breadcrumb type css start //
  .breadcrumb-section-main {
    padding: 70px 0;
    text-align: center;
    .breadcrumb-contain {
      justify-content: center;
      display: block;
      text-align: center;
      > div {
        width: 100%;
        &:last-child {
          width: 100%;
        }
        .breadcrumb-txt {
          text-align: center;
        }
        ul {
          text-align: center;
          li {
            margin-right: 10px ;
            a {
              i {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    &.inner-2 {
      text-align: center;
      .breadcrumb-contain {
        >div {
          width: 100%;
          &:last-child {
            width: 100%;
          }
        }
        ul {
          text-align: center;
          li {
            margin-right: 10px;
            a {
              i {
                margin-right: 10px;
              }
            }
          }
        }
        .breadcrumb-txt {
          margin-bottom: 10px;
        }
      }
    }
    &.inner-3 {
      .breadcrumb-contain {
        ul {
          li {
            margin-right: 0;
            a {
              i {
                margin-right: 5px;
              }
            }
          }
        }
        .breadcrumb-txt {
          margin-top: 2px;
        }
      }
    }
    &.inner-4 {
      .breadcrumb-contain {
        > div  {
          .breadcrumb-txt {
            margin-bottom: 5px;
          }
        }
      }
    }
    &.breadcrumb-section-sm {
      padding: 30px 0;
    }
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .team-section {
    .center-container {
      height: 100%;
    }
    .teams {
      margin-bottom: 20px;
      &:nth-child(n+3) {
        margin-bottom: 0;
      }
    }
  }
  .resume {
    &.about {
      div {
        .radial {
          margin-bottom: 30px;
        }
        .m-t-50 {
          margin-top: 0;
        }
      }
    }
  }
  .music{
    &.sponsor {
      .row {
        > div {
          &:nth-last-child(2) {
            .sponsor-img {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.blog{
      .img-container {
        .blog-info{
          padding: 10px 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    .event-content {
      .link-horizontal {
        ul {
          li {
            a {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .section-404 {
    .navbar-links {
      display: none;
    }

    .custom-navbar {
      text-align: center;
    }

    .brand-logo img {
      width: 120px;
    }

    .box_astronaut {
      top: 70%;
    }
  }
}

@media only screen and (max-width: 575px) {
  .modal1{
    .offer-content{
      > div{
        padding-right: 15px;
      }
    }
  }
  header.nav-lg {
    padding: 1.2rem 1rem;
  }
  header{
    &.ecommerce {
      nav {
        .top-header-right{
          margin-right: 30px;
          ul{
            li{
              a{
                padding: 5px;
              }
            }
          }
        }
      }
    }
  }
  .parallax-banner {
    &.full-banner{
      background-blend-mode: overlay;
      background-color: #d2d2d2;
    }
  }
  .down-banner {
    .banner-contain{
      max-width: 100%;
      margin: 0;
    }
  }
  .banner-contain > div {
    background-color: rgba(128, 128, 128, 0.23);
    padding: 30px;
    margin-right: 15px;
  }
  .modal-bg{
    display: none !important;
  }
  .ecommerce{
    .collection {
      margin: 10px 30px;
    }
    .banner-three {
      padding: 15px 0;
      > div + div {
        margin-top: 15px;
      }
    }
    &.nav-fix{
      padding: 0;
      nav{
        ul{
          padding: 10px 0 !important;
        }
      }
    }
    .contain {
      h1{
        margin-bottom: 20px;
      }
    }
    &.ecommerce-home {
      padding-top: 55px;
      .ecommerce-home-slider{
        .owl-item,.bg-size{
          min-height: 250px;
          height: 70vh;
          background-blend-mode: overlay;
          background-color: #d6d6d6;
        }
      }
    }
    &.collection{
      margin: 30px;
    }
    .owl-nav {
      [class*=owl-]{
        i{
          font-size: 10px;
        }
        &.owl-next {
          bottom: 10%;
          padding: 10px;
          line-height: 0;
        }
        &.owl-prev{
          bottom: 10%;
          padding: 10px;
          line-height: 0;
          right: 50px;
        }
      }
    }

  }
  .layout-ecommerce{
    section {
      padding: 40px 0;
    }
    .ecommerce-footer{
      padding-top: 40px;
    }
  }
  .btn-default {
    padding: 10px 25px;
  }
  .parallax-banner .banner-contain {
    max-width: 100%;
  }
  .portfolio_section{
    > div{
      width: 100%;
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }
  .blog-sidebar {
    .sidebar-container {
      .post-container {
        &:nth-last-child(2) {
          margin-bottom:20px;
        }
      }
    }
  }
  .music .form-footer .form-group input, .music .form-footer .form-group textarea {
    padding: 12px 20px;
  }
  .music .form-footer .form-group {
    margin-bottom: 12px;
  }
  .subscribe.music .subscribe-title {
    padding: 0;
  }
  .booking .form-group input {
    padding: 12px 20px;
  }
  .breadcrumb-section {
    .breadcrumb-text {
      margin-bottom: 10px;
    }
  }
  .wedding {
    .wedding-banner .frem {
      width: 100%;
    }
    .schedule{
      i{
        display: block;
        margin-right: 0;
      }
    }
  }
  header{
    nav{
      >a{
        .img-fluid{
          height: 33px;
        }
      }
    }
  }
  .breadcrumb-section{
    padding: 110px 0 50px 0;
  }
  header{
    border-bottom: 1px solid rgba(31, 35, 44, 0.12);
    padding: 10px 0;
    nav{
      padding: 0 !important;
    }
  }
  .portfolio-metro {
    .man {
      width: 80px;
      margin-bottom: 20px;
    }
    h1{
      margin-bottom: 0;
    }
    .center-content{
      order: 1;
    }
  }
  .yoga {
    &.pricing {
      .price-container {
        margin: 0 30px;
        transform: scale(1) !important;
        .service-feature {
          margin-bottom: -80px;
        }
      }
    }
    &.format {
      .girl-img-container {
        .girl-yoga {
          width: 250px;
        }
      }
      .experience-container {
        .exp-img {
          width: 250px;
        }
      }
      .meter {
        height: 23px;
        margin-top: 10px;
        .progress {
          height: 23px;
        }
      }
    }
  }
  .section-404 {
    .text-404 {
      margin-right: 0;
      margin-left: 0;
    }
    .text-inner {
      margin-top: -26px;
      margin-bottom: -30px;
    }
    .object_astronaut {
      display: none;
    }
    .object_rocket {
      width: 70px;
    }
    .object_earth {
      width: 50px;
    }
    .object_moon {
      width: 40px;
    }
  }
  .checkout-page {
    .checkout-title {
      margin-bottom: 18px;
    }
    .checkout-form {
      .form-group {
        margin-bottom: 15px;
        .field-label {
          margin-bottom: 6px;
        }
      }
      input {
        &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="number"], &[type="url"] {
          height: 35px;
        }
      }
      .checkout-details {
        padding: 15px;
        margin-top: 20px;
        .order-box {
          margin-bottom: 0;
          .total {
            margin-bottom: 25px;
          }
          .sub-total {
            .shipping {
              width: 100%;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  .title {
    &.title2 {
      .borders {
        span {
          &:before, &:after {
            content: unset;
          }
        }
      }
    }
  }
  .pagination_sec ul li {
    height: 38px;
    width: 38px;
    margin: 0 8px;
  }
  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 15px
        }
      }
      .btn-solid {
        padding: 7px 8px;
      }
    }
  }
  .wishlist-section {
    .btn-solid {
      padding: 7px 8px;
    }
  }
  .team-sec {
    &.team-grid {
      .team-para {
        margin-top: 10px;
      }
    }
  }
  .header {
    &.saas2 {
      .header-sub-text {
        margin-top: 20px;
      }
    }
  }
  .saas1 {
    &.howitwork {
      .work-tab {
        .nav-pills {
          .nav-item {
            display: inline-block;
          }
        }
      }
    }
  }
  .theme-pannel-main {
    ul {
      li {
        padding: 5px 0px;
        a {
          font-size: 10px;
        }
      }
    }
  }
  //inner pages end
  .gym {
    &.counter {
      .row {
        .counter-container {
          & + .counter-container {
            padding-bottom: 30px;
            border-left: 1px solid #ddd;
            & + .counter-container {
              border-left: 0;
              padding-top: 30px;
              border-top: 1px solid #ddd;
              & + .counter-container {
                border-left: 1px solid #ddd;
              }
            }
          }
        }
      }
    }
  }
  section, footer {
    padding: 50px 0;
  }
  .p-t-100 {
    padding-top: 50px !important;
  }
  .p-b-100 {
    padding-bottom: 50px !important;
  }
  .m-b-30 {
    margin-bottom: 25px;
  }
  .header {
    .rotate {
      display: none;
    }
    .set-square {
      .left {
        height: 190px;
        &:before {
          width: 190px;
        }
      }
      .right {
        width: 190px;
      }
    }
    .right-line {
      left: calc(50% + 95px);
      height: 200px;
    }
    &.event {
      .set-bottom {
        bottom: 20px;
      }
    }
    &.app1 {
      h1 {
        line-height: 38px;
        margin-top: -5px;
      }
    }
  }
  .pricing {
    .siema {
      .price-container {
        &.price-margin {
          margin: 5px 0;
        }
      }
    }
  }
  .title {
    .sub-title {
      line-height: 20px;
    }
  }
  .about {
    &.app1 {
      .counters {
        border-right: unset;
        &:nth-child(3) {
          border-bottom: 1px solid $white;
        }
        img {
          margin-bottom: 15px;
        }
        h3 {
          font-size: 225%;
          margin-bottom: 0;
          line-height: 35px;

        }
        .abouts {
          padding: 20px 0;
          img {
            height: 30px;
          }
        }
        &:first-child {
          .abouts {
            padding-top: 0;
          }
        }
        &:last-child {
          .abouts {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .format {
    .counters {
      &.set-order-2 {
        order: 2;
      }
      &.set-height {
        text-align: center;
      }
    }
  }
  .services {
    &.app2 {
      .service-container {
        &:nth-child(n+5) {
          .service {
            margin-bottom: 30px;
          }
        }
        &:nth-child(n+7) {
          .service {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .subscribe {
    .form-group {
      input {
        padding: 18px 35px;
        padding-left: 15px;
      }
      .d-flex {
        .button-primary {
          button {
            padding: 12px 20px;
            font-size: 12px;
          }
        }
      }
      .form-control {
        font-size: 12px;
      }
    }
  }
  .download {
    .download-img {
      &.d-flex {
        display: block !important;
        text-align: center;
        .download-icon {
          margin-bottom: 20px;
        }

      }
    }
    .information {
      p {
        text-align: center;
      }
    }
    .link-horizontal {
      ul {
        justify-content: center;
      }
    }
  }
  .pricing {
    .feature-text {
      margin-bottom: 30px;
      .price-value {
        font-weight: 600;
        margin-bottom: 25px;
      }
    }
    .service {
      .service-feature {
        h6 {
          margin-bottom: 10px;
        }
      }
    }
    .price-container {
      margin-bottom: 20px;
      .price-feature-container {
        .price-value {
          .price {
            .large {
              font-size: 40px;
            }
          }
        }
      }
    }
    &.app2 {
      .feature-text {
        margin-bottom: 0;
      }
    }
  }
  .app1 {
    p {
      line-height: 26px;
    }
    .socials-lists {
      ul {
        li {
          a {
            i {
              height: 40px;
              width: 40px;
              font-size: 15px;
            }
          }
        }
      }
      &.m-t-50 {
        margin-top: 25px;
      }
    }
    &.services {
      .container {
        margin-bottom: -20px;
      }
    }
  }
  .videos {
    &.app1 {
      .video {
        .button {
          img {
            height: 40px;
          }
        }
      }
    }
    &.saas1 {
      .video {
        .button {
          img {
            height: 30px;
          }
        }
      }
    }
  }
  .event {
    .wedding-time {
      .row {
        > div {
          &:nth-child(odd) {
            .count {
              margin-left: auto;
            }
          }
          &:nth-child(even) {
            .count {
              margin-right: auto;
            }
          }
        }
      }
    }
    h1 {
      margin-top: -5px;
    }
    .socials-lists {
      ul {
        li {
          a {
            i {
              height: 40px;
              width: 40px;
              font-size: 15px;
            }
          }
        }
      }
    }
    .wedding-time {
      margin-bottom: 30px;
    }
    div {
      .event-content {
        .count {
          max-width: 130px;
          margin-bottom: 15px;
        }
      }
      &:nth-child(n+3) {
        .event-content {
          .count {
            margin-bottom: 0;
          }
        }
      }
    }
    .circle {
      top: 15px;
      &:after {
        top: -18px;
        height: 15px;
      }
    }
  }
  .videos {
    .video-description {
      p {
        margin-bottom: 15px;
      }
    }
    .video {
      padding: 95px 0;
    }
  }
  .team-section {
    .teams {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .event, .agency {
    &.speaker {
      .container {
        margin-bottom: -30px;
      }
      .speker-container {
        margin-bottom: 30px;

      }
      .team-img {
        margin-bottom: 15px;
      }
    }
  }
  .event, .saas2 {
    &.testimonial {
      .testimonial {
        .owl-theme {
          .owl-prev, .owl-next {
            display: none;
          }
        }
        .testimonial-msg {
          margin-bottom: 20px;
          img{
            height: 70px;
          }
        }
      }
      h6 {
        text-align: center;
      }
      .rating {
        display: block;
        text-align: center;
        .stars {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
  .event {
    .title {
      &.title3 {
        .sub-title {
          p {
            line-height: 20px;
            margin-bottom: -4px;

          }
        }
      }
    }
    &.sponsor {
      .sponsor-img {
        margin-bottom: 30px;
        &:nth-child(n+10) {
          margin-bottom: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.contact {
      .row {
        padding: 0 15px;
      }
      .iframe-container {
        height: 250px;
      }
      .form-footer {
        padding-left: 15px;
        .bg-white {
          form {
            padding: 25px;
            .form-group {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

  }
  .schedule {
    .cal-time {
      &.events {
        padding: 20px;
      }
    }
  }
  .event, .wedding, .resume {
    &.gallery {
      .gallery-list {
        margin-bottom: 25px;
      }
      .list {
        a {
          padding: 0 10px;
        }
      }
    }
  }
  .gym {
    h1 {
      margin-top: -8px;
    }
    &.bg-cal-bmi {
      .link-horizontal {
        &.m-t-40 {
          margin-top: 10px;
        }
        &.m-b-40 {
          margin-bottom: 10px;
        }
      }
      .calculate-bmi {
        margin-top: 30px;
      }
    }
    &.copyright {
      .link-horizontal {
        margin-bottom: 15px;
      }
    }
    &.bg-cal-bmi {
      .btn-white {
        margin-bottom: 20px;
      }
    }
  }
  .music {
    &.header {
      .music-content {
        .center-text {
          height: calc(401px - 50px);
          margin-top: 50px;
        }
      }
    }
    .music-container{
      margin-top: unset;
    }
    .row {
      div {
        &:nth-child(n+7) {
          .album-list {
            margin-bottom: 30px;
          }
        }
        &:last-child {
          .album-list {
            margin-bottom: 0;
          }
        }
      }
    }
    &.sponsor {
      div {
        &:nth-child(n+11) {
          .sponsor-img {
            margin-bottom: 30px;
          }
        }
        &:nth-child(n+13) {
          .sponsor-img {
            margin-bottom: 0;
          }
        }
      }
    }
    .title {
      &.title3 {
        .sub-title {
          p {
            line-height: 20px;
          }
        }
      }
    }
    &.bg-footer {
      .row {
        div {
          .contact-details {
            margin-bottom: 25px;
            .contact-heading {
              margin-bottom: 10px;
            }
          }
          &:last-child {
            .contact-details {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .audioplayer {
      padding: 19px;
    }
  }
  .agency {
    &.header {
      .bg {
        background-position: unset;
      }
      .plus-container {
        display: none;
      }
    }
    .icon-collection {
      display: flex;
      margin-bottom: 15px;
      .about-icon {
        margin: 0 5px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          padding: 15px 8px;
        }
      }
    }
    &.testimonial {
      .testimonial-slider {
        .owl-nav {
          display: none;
        }
      }
    }
    .para2 {
      margin-bottom: 20px;
    }
    &.video {
      .d-inline-block {
        .bg-video {
          width: auto;
        }
      }
    }
    &.copyright {
      .link-horizontal {
        margin-bottom: 10px;
      }
    }
    &.blog{
      &.blog-sec{
        &.blog-list, &.blog-split, &.blog-sidebar{
          .row{
            // padding-left: 15px;
            // padding-right: 15px;
          }
        }
      }
      .blog-agency{
        .blog-contain{
          .img-container{
            .blog-info{
              padding: 20px;
            }
          }
        }
      }
    }
  }
  .wedding {
    .wedding-banner {
      .text-container {
        .couple-text {
          .banner-text {
            font-size: 45px;
          }
          .banner-text {
            &.small {
              font-size: 35px;
            }
          }
        }
        .place {
          font-size: 20px;
        }
        .address{
          font-size: 11px;
          margin-top: 0;
        }
      }
    }
  }
  .saas1 {
    &.howitwork {
      .work-tab {
        .nav-pills {
          display: flex;
          .nav-link {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            h6 {
              display: flex;
              align-items: center;
              margin-top: 0;
              margin-left: 20px;
            }
            span {
              bottom: -12px;
            }
          }
        }
      }
    }
    &.pricing {
      .pricing__switcher {
        width: 260px;
      }
      .switcher__button {
        font-size: 14px;
      }
      .pricing__switcher {
        margin: 0 auto 50px auto;
      }
    }
    &.build-bg {
      .owl-carousel {
        .owl-item {
          .work-slide {
            height: 300px;
          }
        }
      }
    }
    .title {
      .sub-title {
        .p-padding {
          padding: 0;
        }
      }
    }
  }
  .saas2 {
    &.feature {
      margin-bottom: -35px;
      .center-content {
        margin-bottom: 35px;
        img {
          height: 40px;
        }
        .feature-content {
          margin-left: 20px;
        }
      }
    }
    &.laptop-slider {
      .slider-container {
        .center-img {
          img {
            height: 140px;
            width: 232px;
          }
        }
        .item {
          img {
            height: 102px;
            width: 153px;
          }
        }
        .trainers-slider {
          &.owl-carousel {
            .owl-stage-outer {
              padding-top: 0;
            }
          }
        }
      }
    }
    &.pricing {
      .feature-text {
        margin-bottom: 0;
      }
    }
    &.testimonial {
      .container {
        margin-bottom: -15px;
      }
    }
  }
  .resume {
    &.about {
      .row {
        div {
          .radial {
            margin-bottom: 30px;
          }
          &:last-child {
            .radial {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .artist {
    .artist-text {
      p {
        &.text-para {
          line-height: 20px;
          font-size: 95%;
        }
      }
    }
  }
  .subscribe {
    &.music {
      .subscribe-title {
        .subscribe-main {
          font-size: 120%;
          letter-spacing: 0.5px;
        }
      }
    }
  }
  .testimonial {
    &.wedding {
      .testimonial-container {
        img {
          height: 30px;
        }
        .testimonial-info {
          margin-top: 30px;
          .testimonial-para {
            margin-bottom: 20px;
            line-height: 26px;
            font-size: 120%;
          }
        }
      }
    }
  }
  .list {
    ul {
      li {
        padding: 4px 20px;
        img {
          height: 15px;
          &.play {
            height: 32px;
          }
        }
      }
    }
  }
  .copyright {
    .link-horizontal {
      ul {
        flex-wrap: wrap;
        li {
          padding-right: 15px;
        }
      }
    }
    .copyright-text {
      text-align: center !important;
    }
    &.wedding {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
    }
  }
  .app2 {
    &.team-section {
      .teams {
        .our-team {
          .set-relative {
            &.set-border {
              padding-bottom: 30px;
            }
          }
          .borders {
            height: 30px;
          }
        }
      }
    }
    .subscribe {
      margin-top: 30px;
      .form-group {
        .icon {
          top: 27px;
        }
      }
    }
    &.about, &.quality {
      padding-top: 50px;
      .set-height {
        min-height: unset;
        .mobile1, .mobile2 {
          width: 250px;
          position: relative;
        }
      }
    }
    &.quality {
      .set-height {
        .mobile2 {
          top: -17px;
        }
      }
    }
    .abouts {
      .logo {
        height: 60px;
      }
      .set-border {
        margin-bottom: 12px;
      }
    }
    &.about {
      .set-height {
        .j-img {
          display: none;
        }
      }
    }
    &.format {
      .logo {
        padding-bottom: 10px;
      }
      .abouts {
        text-align: center;
      }
      .icon-collection {
        justify-content: center;
      }
      .format-head-text {
        margin-bottom: 20px;
      }
      .format-sub-text {
        margin-bottom: 15px;
      }
      .m-t-50 {
        margin-top: 14px;
      }
    }
    &.team{
      .team-slider {
        .team-container{
          margin-left: 15px;
        }
      }
    }
  }
  .portfolio-creative {
    .head-sub-text{
      padding: 0 15px;
    }
    &.creative3 {
      .portfolio-text {
        &.d-flex {
          display: block !important;
          padding: 30px 0;
        }
        .w-75 {
          width: unset !important;
          .header-sub-text {
            margin-top: 0;
          }
        }
        .text-right {
          text-align: left !important;
          margin-top: 15px;
        }
      }
    }
  }
  .counter-container {
    & + .counter-container {
      padding-bottom: 30px;
      border-left: 1px solid #ddd;
      & + .counter-container {
        border-left: 0;
        padding-top: 30px;
        border-top: 1px solid #ddd;
        & + .counter-container {
          border-left: 1px solid #ddd;
        }
      }
    }
  }
  .p-b-160{
    padding-bottom: 135px;
  }
  footer{
    &.event{
      &.contact{
        .form-footer{
          form{
            .row{
              div{
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .gym, .yoga {
    .trainers-slider {
      &.owl-carousel {
        .owl-stage-outer {
          min-height: 388px !important;
        }
      }
    }
  }

  // inner pages //
  .template-password {
    #container {
      #login {
        margin-bottom: 0;
      }
    }
  }
  .error-section {
    padding: 100px 0;
    h1 {
      font-size: 100px;
    }
    h2 {
      margin: 20px 0;
      font-size: 18px;
    }
  }
  .about-page {
    .about-head {
      display: inline-block;
      width: 100%;
      text-align: center;
      .theme-breadcrumb {
        .breadcrumb {
          justify-content: center;
          padding-top: 10px;
          padding-bottom: 0;
        }
      }
    }
  }
  .blog-detail-page {
    .blog-detail {
      .post-social {
        li {
          display: block;
          & + li {
            padding-left: 0;
            margin-left: 0;
            border-left: none;
          }
        }
      }
    }
  }
  .collection {
    .collection-block {
      .collection-content {
        p {
          margin-bottom: 10px;
        }
        h4, h3 {
          margin-bottom: 5px;
        }
      }
    }
  }
  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-box {
        margin-top: 30px;
      }
    }
    .product-top-filter {
      .product-filter-content {
        .search-count {
          padding: 10px 20px 10px 20px;
          h5 {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .product-page-per-view, .product-page-filter {
          width: 100%;
          select {
            padding: 10px 20px 10px 20px;
          }
          &:before {
            top:11px;
          }
        }
        .product-page-per-view {
          border-right:1px solid $round-border !important;
          border-bottom: 1px solid $round-border;
        }
      }
      .popup-filter {
        .search-count {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .product-page-per-view {
          border-bottom: 1px solid $round-border;
          width: 100%;
          select {
            border-right: none;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          &:before {
            top:14px;
          }
        }
        .product-page-filter {
          width: 100%;
          border-top: none;
          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          &:before {
            left:unset;
            right: 35px !important;
            top:14px;
          }
        }
      }
    }
  }
  .cart-section {
    .cart-buttons {
      > div {
        &:last-child {
          padding-right: 15px
        }
      }
      .btn-solid {
        padding: 7px 8px;
      }
    }
  }
  .wishlist-section {
    .btn-solid {
      padding: 7px 8px;
    }
  }
  .collection-wrapper {
    .order-up {
      order:-1;
    }
  }
  .checkout-page {
    .checkout-form {
      .checkout-details {
        padding: 15px;
      }
    }
  }
  .search-product {
    > div {
      &:nth-last-child(5) {
        margin-top: 30px;
      }
    }
  }
  .product-box {
    .img-wrapper {
      .lable-block {
        .lable3 {
          font-size: 12px;
          padding: 13px 8px;
        }
      }
    }
    .img-block {
      .lable-wrapper {
        .lable1, .lable2 {
          padding: 6px 9px 8px 15px;
          font-size: 12px;
        }
      }
    }
  }
  .tab-product, .product-full-tab {
    .tab-content {
      &.nav-material {
        p {
          padding: 15px 0;
        }
      }
    }
  }
  .tab-product {
    .tab-content {
      &.nav-material {
        p {
          line-height: 1.3;
        }
      }
    }
  }
  .slider-right-nav {
    .slick-slide {
      &:first-child {
        > div {
          margin-top: 15px;
        }
      }
    }
  }
  .lookbook {
    .lookbook-block {
      .lookbook-dot {
        .dot-showbox {
          width: 90px;
          top:-70px;
          .dot-info {
            h5 {
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .layout7-product {
    .product-box {
      .details-product {
        padding: 5px;
      }
      &:hover {
        .details-product {
          animation: none;
        }
      }
    }
  }
  .padding-cls {
    padding-left: 15px;
    padding-right: 15px;
  }
  .success-text {
    i {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.2;
    }
    h2 {
      margin-bottom: 10px;
    }
  }
  .order-success-sec {
    >div  {
      margin-top: 15px;
    }
  }
  .delivery-sec {
    padding: 15px;
    margin-top: 15px;
    h3, h2 {
      font-size: 20px;
    }
  }
  .product-order  {
    h3 {
      font-size: 20px;
    }
    .product-order-detail {
      .order_detail {
        h4, h5 {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }
    .total-sec {
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .final-total {
      h3 {
        font-size: 16px;
      }
    }
  }
  .beauty-about {
    .service {
      .service-block1 {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .main-menu {
    .menu-left {
      .navbar {
        i {
          font-size: 24px;
        }
      }
    }
  }
  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 0;
            }
          }
          .btn-solid {
            &.btn-sm {
              padding: 3px 12px;
            }
          }
        }
      }
    }
  }
  .bundle {
    .bundle_img {
      .img-box {
        img {
          max-width: 70px;
        }
      }
    }
    .bundle_detail {
      .theme_checkbox {
        padding: 0;
      }
    }
  }


  // breadcrumb-section //
  .breadcrumb-section-main {
    padding: 50px  0;
    .breadcrumb-text {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .p-2col-width {
    .p-width {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 12px;
    }
  }
  .p-3col-width {
    .p-width {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .p-4col-width {
    .p-width {
      max-width: 100%;
      flex: 100%;
    }
  }
  .m-layout {
    .m-width {
      &.m-4-col, &.m-2-col, &.m-3-col{
        width: 100%;
        max-width: 100%;
        flex: 0 100%;
      }
    }
  }
  .saas1.subscribe input[type="email"]{
    width: 220px;
  }
  .saas2.pricing .price-container .price-feature-container {
    margin-top: -50px;
  }
  .maintenance-sec .center-content button, .coming-soon .center-content button {
    margin-left: 0;
  }
  .copyright.resume .link-horizontal ul li a {
    line-height: 1.8;
  }
  .wedding.bg.countdown-bg .counter-container .gradient-text {
    margin-top: 10px;
  }
  .portfolio-metro .portfolio_section h6{
    padding-top: 20px;
  }
  header {
    .navbar {
      width: 300px;
    }
  }
  footer.footer2.agency .link-horizontal ul li.borders-right {
    margin-right: 2px;
  }
  .counter .counters.hover-counter {
    padding: 15px 0;
  }
  .saas1{
    &.testimonial {
      .testimonial-box {
        padding: 20px;
      }
    }
  }
  .compare-page {
    .table-wrapper {
      .table {
        .featured-image {
          width: 100px;
        }
      }
    }
  }
  .coming-soon {
    .our-web-text {
      letter-spacing: 0;
    }
    .coming-logo {
      margin-bottom: 30px;
      width: 100px;
    }
    .launch-text {
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .launch-text2 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .counters-content {
      .count {
        border-right: none;
      }
    }
    .form-group {
      .d-flex {
        display: block !important;
        input {
          margin-bottom: 15px;
        }
      }
    }
    &.coming-soon-2 {
      .set-bg {
        padding-top: 30px;
      }
    }
  }
  .side-section {
    width: 270px;
  }
  .slider-container {
    .trainers-slider {
      .item {
        margin: 0 0;
      }
    }
  }
  .saas1.howitwork .work-tab .nav-pills .nav-link {
    padding: 0 0;
  }
  .wedding {
    &.brand-slider {
      .owl-carousel {
        img {
          max-width: 200px;
          margin: 0 auto;
        }
      }
    }
    &.brand-sliders {
      .brand-slider {
        .item {
          img {
            height: auto;
            width: auto;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .event {
    &.about {
      .text-right {
        width: 80%;
      }
    }
  }
  //inner pages start
  .portfolio-section {
    &.videos {
      .m-b-50 {
        margin-bottom: 0;
      }
      .video {
        padding: 120px 0;
      }
    }
  }
  //inner pages end
  .app2 {
    .subscribe {
      .form-group {
        .button-primary {
          .btn {
            margin-top: 0;
          }
        }
      }
    }

    h1 {
      line-height: 36px;
    }
  }
  h1 {
    line-height: 40px;
  }
  .header {
    .wave {
      display: none;
    }
  }
  .brand-sliders {
    .brand-slider {
      .item {
        img {
          height: 25px;
          width: auto;
        }
      }
    }
  }
  .m-b-25 {
    margin-bottom: 15px;
  }
  .videos {
    .video {
      padding: 80px 0;
    }
    .video-description {
      p {
        margin-bottom: 10px;
      }
    }
  }
  .m-b-35 {
    margin-bottom: 20px;
  }
  .subscribe {
    &.subscribe-2 {
      .form-group {
        input {
          padding-left: 95px;
        }
      }
    }
  }
  .saas1 {
    &.build-bg {
      .owl-carousel {
        .owl-item {
          .work-slide {
            height: 250px;
          }
        }
      }
    }
    &.testimonial {
      .video-box {
        img {
          padding: 100px;
        }
      }
      .testi-profile {
        .media-body {
          margin-top: 20px;
        }
      }
    }
  }
  .saas2 {
    &.laptop-slider {
      .slider-container {
        .center-img {
          img {
            display: none;
          }
        }
        .item {
          img {
            height: auto;
            width: auto;
            max-width: 100%;
          }
        }
      }
    }
  }
  .event, .wedding, .resume {
    &.gallery {
      .gallery-list {
        margin-bottom: 15px;
      }
      .list {
        display: block;
        column-count: 2;
        a {
          padding: 0;
          width: 100%;
          display: -webkit-box;
          margin-bottom: 10px;
        }
      }
    }
  }
  .gym {
    .about-img {
      margin: 25px 0;
      margin-bottom: 50px;
      .borders-around {
        .bg-black {
          width: 140px;
          &:before, &:after {
            width: 114px;
            height: 114px;
          }
          &:before {
            top: -25px;
            left: -25px;
          }
          &:after {
            bottom: -25px;
            right: -25px;
          }
        }
      }
    }
  }
  .agency {
    .icon-collection {
      display: block;
      margin-bottom: 0;
      .about-icon {
        margin: 0;
        margin-bottom: 15px;
        a {
          padding: 25px 10px;
        }
        &:first-child, &:last-child {
          margin-bottom: 15px;
        }
      }
    }
    .row {
      .center-content {
        &:last-child {
          .icon-collection {
            &:last-child {
              .about-icon {
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    &.video {
      margin-top: 20px;
      .m-b-20 {
        margin-bottom: 10px;
      }
      .abs-center {
        width: 100%;
      }
      .video-img {
        height: 50px;
      }
      .video-heading {
        margin-bottom: 12px;
      }
    }
  }
  .wedding {
    .wedding-banner {
      .text-container {
        .couple-text {
          margin-bottom: 5px;
          .banner-text {
            font-size: 35px;
          }
          .banner-text {
            &.small {
              font-size: 27px;
            }
          }
        }
        .place {
          font-size: 18px;
        }
      }
    }
    .wedding-time {
      margin-bottom: 10px;
    }
    .wedding-time {
      .count {
        h2 {
          font-size: 310%;
        }
        .days {
          font-size: 85%;
        }
        .counter-container {
          padding: 35px 0;
        }
      }
    }
    .schedule {
      .m-r-15 {
        margin-right: 5px;
      }
    }
  }
  .testimonial {
    &.music {
      .testimonial-info {
        margin-top: 20px;
      }
      .music-testimonial-slider {
        &.owl-theme {
          .item {
            img {
              height: 30px;
            }
          }
        }
      }
    }
    &.wedding {
      .testimonial-container {
        margin-bottom: 10px;
        padding: 15px;
        .border-around {
          padding: 15px 0;
        }
        img {
          height: 25px;
        }
        .testimonial-info {
          margin-top: 25px;
          .testimonial-para {
            line-height: 20px;
            font-size: 12px;
          }
        }
      }
    }
    .testimonial-slider {
      .testi-profile {
        text-align: center;
      }
      .media {
        display: inline-block;
        img {
          margin: 0 auto;
          height: 100px;
          width: 100px;
        }
        .media-body {
          padding: 0;
          text-align: center;
        }
      }
    }
  }
  .event, .wedding, .resume {
    &.gallery {
      .list {
        ul {
          li {
            padding: 3px 10px;
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .yoga {
    &.event {
      .event-container {
        text-align: center;
        display: block !important;
        .yoga-circle {
          position: relative;
          left: 0;
          margin-bottom: 10px;
        }
      }
    }
    &.event {
      .event-container {
        .event-info {
          margin-left: 0;
        }
      }
    }
  }
  .gym, .yoga {
    .trainers-slider {
      &.owl-carousel {
        .owl-stage-outer {
          min-height: 380px !important;
        }
      }
    }
  }



  // inner pages //
  .select_input {
    select {
      padding: 12px 15px;
      margin-bottom: 18px;
    }
  }
  .about-page {
    p {
      line-height: 1.5;
    }
  }
  .blog-detail-page {
    .blog-contact {
      h2 {
        font-size: 30px;
        margin-bottom: 25px;
      }
      .theme-form {
        input, textarea {
          padding: 12px 20px;
          margin-bottom: 20px;
        }
      }
    }
    .blog-detail {
      h3 {
        font-size: 20px;
        margin-bottom: 15px;
      }
      img {
        margin-bottom: 25px;
      }
    }
    .blog-advance {
      ul {
        line-height: 1.5;
      }
      img {
        margin-bottom: 20px;
        margin-top: 10px;
      }
      p {
        line-height: 1.5;
      }
    }
    .comment-section {
      li {
        padding-top: 30px;
        padding-bottom: 30px;
        p {
          line-height: 1.5;
        }
        h6 {
          margin-top: 10px;
          margin-bottom: 10px;
          span {
            display: inherit;
            margin-left: 0;
          }
        }
      }
      .media {
        display: inline-block;
        text-align: center;
      }
    }
  }
  .faq-section {
    .theme-accordion {
      .card {
        .card-body {
          p {
            line-height: 1.5 !important;
          }
        }
        .card-header {
          button {
            line-height: 1.3;
            padding: 12px 35px 12px 12px !important;
          }
        }
      }
    }
    .accordion {
      &.theme-accordion {
        .card {
          .card-header {
            button {
              &:before {
                top:20px;
              }
            }
          }
        }
      }
    }
  }
  .pwd-page {
    padding: 70px 0;
    h2 {
      font-size: 25px;
      margin-bottom: 15px;
    }
  }
  .login-page {
    .authentication-right {
      h6 {
        margin-bottom: 10px;
      }
    }
    .theme-card {
      padding: 25px;
    }
  }
  .register-page {
    .theme-card {
      padding: 18px;
      .theme-form {
        input {
          margin-bottom: 20px;
          padding: 12px 15px;
        }
      }
    }
  }
  .search-block {
    .btn-solid {
      padding: 10px 15px;
    }
  }
  .contact-page {
    .map {
      iframe {
        height: 275px;
      }
    }
    .theme-form {
      input {
        padding: 12px 15px;
        margin-bottom: 18px;
      }
    }
  }
  .blog-page {
    .blog-media {
      .blog-right {
        h6 {
          margin-bottom: 0;
        }
        p {
          line-height: 1.3;
        }
        h4 {
          margin-bottom: 5px;
        }
        ul {
          margin-bottom: 5px;
          li {
            display: block;
            & + li {
              padding-left: 0;
              margin-left: 0;
              border-left: none;
            }
          }
        }
      }
    }
  }
  .account-sidebar {
    width: 45%;
  }
  .success-text {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .delivery-sec {
    h3, h2 {
      font-size: 18px;
    }
  }
  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            margin: 5px 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  //inner pages start
  .weeding{
    &~.tap-top{
      bottom: 100px;
    }
  }
  .saas2.laptop-slider .slider-container{
    margin-bottom: -30px;
  }
  section.gym.trainers{
    .title{
      margin-bottom: -30px;
    }
  }
  .yoga {
    &.brand-slider {
      .owl-carousel {
        .item {
          img {
            max-width: 250px;
            margin: 0 auto;
          }
        }
      }
    }
    .title {
      &.title2 {
        .borders {
          span {
            &:before, &:after {
              width: 55px;
            }
          }
        }
      }
    }
  }
  footer {
    &.wedding {
      &.copyright {
        .footer-titles.mobile-title h3{
          text-align: center;
          margin-bottom: 5px;
        }
        .link-horizontal ul.link li{
          text-align: center;
        }

        padding: 20px 0;
        .link-horizontal {
          ul {
            margin-bottom: 0px;
            li {
              a {
                i {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
      .footer-headings {
        display: none;
      }
      .footer-titles {
        &.mobile-title {
          display: block;
        }
      }
      .footer-contants {
        padding: 10px 0;
      }
      .footer-titles {
        padding: 5px 0;
        text-align: left;
        border-bottom: 1px solid rgba($white, 0.2);
        position: relative;
        &.active {
          .according-menu {
            font: normal normal normal 15px/1 FontAwesome;
            &:before {
              content: "\f106";
              position: absolute;
              right: 2px;
              bottom: 10px;
            }
          }
        }

        .according-menu {
          font: normal normal normal 15px/1 FontAwesome;
          &:before {
            content: "\f107";
            position: absolute;
            right: 2px;
            bottom: 10px;
          }
        }
      }
    }
  }
  //inner pages end
  .header {
    &.music {
      .music-content {
        .center-text {
          height: calc(401px - 45px);
          margin-top: 45px;
        }
      }
    }
    &.wedding {
      .center-text {
        height: auto;
        margin-top: 63px;
      }
    }
  }
  .subscribe {
    .form-group {
      margin: 0;
      input {
        height: 50px;
        margin-bottom: 20px;
      }
      .d-flex {
        display: block !important;
        text-align: center;
        .button-primary {
          width: 100%;
          position: relative;
          button {
            margin: 0 auto;
          }
        }
      }
    }
    .social {
      i {
        height: 40px;
        width: 40px;
      }
    }
    &.subscribe-2 {
      .form-group {
        .icon {
          font-size: 25px;
          top: 25%;
          left: 35px;
        }
        .btn-default {
          border: 1px solid $white;
        }
      }
    }
  }
  .app2 {
    .link-horizontal {
      .btn-default {
        padding: 15px 20px;
      }
    }
  }
  .title {
    &.title2 {
      .para {
        font-size: 80%;
      }
    }
  }
  .wedding {
    &.bg {
      &.attend-bg {
        padding-bottom: 80px;
      }
    }
    .wedding-banner {
      .text-container {
        .couple-text {
          margin-bottom: 25px;
          .banner-text {
            &.small {
              font-size: 16px;
            }
          }
        }
        .place {
          font-size: 16px;
        }
      }
    }
    &.copyright {
      .link-horizontal {
        ul {
          &.link {
            display: block;
            li {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
  .videos .w-100 {
    width: 100% !important;
  }
  .agency {
    &.header {
      .btn-default {
        padding: 10px 12px;
      }
    }
  }
  .yoga, .resume {
    &.copyright {
      .link-horizontal {
        &.social-link {
          margin-bottom: 15px;
        }
      }
      hr {
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }
  }
  .saas1 {
    &.build-bg {
      .owl-carousel {
        .owl-item {
          .work-slide {
            height: 220px;
          }
        }
      }
      .build-right-slider {
        margin-left: 40px;
      }
      .owl-dots {
        transform: translate(-25%, -50%);
      }
      .owl-theme {
        .owl-dots {
          .owl-dot {
            span {
              margin-bottom: 20px;
              width: 45px;
            }
            &.active {
              span {
                width: 55px;
              }
            }
          }
        }
      }
    }
    &.subscribe {
      .subscribe-input {
        .text {
          margin-top: 20px;
          display: block;
        }
      }
    }
  }
  .saas2{
    &.pricing{
      .price-container {
        .price-features{
          margin-top: 30px;
        }
      }
    }
  }
  .inner-pages-footer{
    &.resume{
      .link-horizontal{
        &.social-link{
          margin-bottom: -5px;
        }
      }
    }
  }


  .blog-details {
    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }
  .login-page {
    .authentication-right {
      p {
        line-height: 1.5;
      }
    }
    .theme-card {
      .theme-form {
        input {
          padding: 15px 20px;
          margin-bottom: 25px;
        }
      }
    }
  }
  .tab-product {
    .nav-tabs {
      display: block;
      .nav-item {
        width: 100%;
        .nav-link {
          padding: 10px 20px;
        }
      }
    }
  }
  .vertical-tab {
    &.tab-product, .product-full-tab {
      .nav-material {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
  .product-right {
    .product-buttons {
      .btn-default{
        padding: 7px 13px;
      }
    }
    &.product-form-box {
      .timer {
        padding-left: 29px;
        span {
          width: 45px;
        }
      }
    }
    .timer {
      padding-left: 35px;
      span {
        width: 45px;
      }
    }
    .product-icon {
      .product-social {
        margin-top: 0;
        li {
          padding-right: 5px;
          a {
            i {
              font-size: 14px;
            }
          }
        }
      }
      .wishlist-btn {
        i {
          font-size: 14px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
  .tab-product {
    .theme-form {
      input, textarea {
        font-size: 12px;
        padding: 8px 15px;
      }
    }
  }
  .order-box {
    .sub-total {
      .shipping {
        width: unset;
        float: unset;
        display: flex;
        .shopping-option {
          &:last-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
  .checkout-page {
    .checkout-form {
      .form-group {
        margin-bottom: 15px;
      }
      input {
        &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="number"], &[type="url"] {
          height: 40px;
        }
      }
      select, textarea {
        height: 40px;
      }
    }
  }
  .team {
    h6 {
      line-height: 15px;
    }
  }
  .gym, .yoga {
    .trainers-slider {
      &.owl-carousel {
        .owl-stage-outer {
          min-height: 310px !important;
        }
      }
    }
  }
  .payment-box {
    button {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 360px) {

  //inner pages start
  .yoga {
    &.pricing {
      .price-container {
        .service-feature {
          margin-bottom: -50px;
        }
      }
      .owl-theme {
        .owl-dots {
          margin-top: 20px !important;
        }
      }
    }
  }

  .agency {
    &.blog {
      .blog-info {
        > .d-flex {
          display: block !important;
        }
      }
    }
  }
  .cart-section, .wishlist-section {
    .btn-default {
      padding: 14px 12px;
    }
    tbody {
      tr {
        td {
          min-width: 115px;
          .mobile-cart-content {
            .col-xs-3 {
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          a {
            img {
              height: 80px;
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 27px;
          h2 {
            font-size: 21px;
          }
        }
      }
    }
  }
  .wishlist-section {
    .wishlist-buttons {
      &:last-child {
        a {
          margin-left: 0;
        }
      }
    }
  }
  //inner pages end
  .filter-main-btn span {
    width: 100%;
  }
  .header {
    &.wedding {
      .center-text {
        margin-top: 63px;
        .header-sub-text {
          font-size: 12px;
        }
      }
      .wedding-content {
        .girl {
          img {
            height: 330px;
          }
        }
        .logo-h1 {
          height: 50px;
        }
        .btn-default {
          padding: 10px 20px;
        }
      }
    }
    &.saas1 {
      h1 {
        letter-spacing: 1px;
      }
    }
    &.music {
      .music-content {
        .center-text {
          height: calc(401px - 40px);
          margin-top: 40px;
        }
      }
    }
  }
  .app2 {
    .link-horizontal {
      .btn-default {
        padding: 10px 16px;
      }
    }
    .title {
      .title-img {
        height: 55px;
      }
    }
  }
  .schedule {
    .cal-time {
      i {
        &.fa-angle-left {
          left: 10px;
        }
        &.fa-angle-right {
          right: 10px;
        }
      }
    }
  }
  .gym, .yoga, .saas2 {
      .default-dots {
        .owl-dots {
          bottom: 30px !important;
        }
    }
    .btn-default {
      padding: 10px 18px;
    }
    .owl-carousel {
      &.trainers-slider {
        &.owl-carousel {
          .owl-item {
            &.active {
              &.center {
                .item {
                  img {
                    margin-bottom: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .music {
    .cd-animation {
      height: 250px;
    }
    &.side-section {
      .jp-type-playlist {
        .jp-gui {
          .girls {
            width: 150px;
            padding: 15px;
          }
          .gradient-block {
            .now-play {
              padding-top: 15px;
            }
          }
        }
      }
      .side-player {
        .jp-progress {
          margin-top: 20px;
        }
      }
      .jp-audio {
        .jp-time-holder {
          padding-top: 8px;
        }
      }
      .jp-controls {
        .button-container {
          padding: 15px;
        }
      }
      .jp-playlist {
        margin-top: 15px;
      }
      .btn-sidebar {
        padding: 12px 18px;
      }
    }
  }
  .wedding {
    .bottom-img {
      height: 20px;
    }
    .wedding-banner {
      .text-container {
        .couple-text {
          margin-bottom: 0;
          .banner-text {
            &.small {
              font-size: 16px;
            }
          }
        }
        .place {
          font-size: 15px;
        }
      }
    }
    &.pricing {
      .m-4 {
        margin: 0 !important;
      }
    }
  }
  .event {
    div {
      .event-content {
        .count {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  .saas1 {
    &.build-bg {
      .owl-carousel {
        .owl-item {
          .work-slide {
            height: 180px;
          }
        }
      }
    }
    &.testimonial {
      .testimonial-box {
        padding: 30px 20px;
        h6 {
          line-height: 24px;
        }
      }
    }
  }
  .list {
    ul {
      li {
        padding: 4px 10px;
      }
    }
  }
  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter, .product-filter-content {
        .product-page-per-view {
          width: 100%;
          border-bottom: 1px solid $border-white;
          border-left: 1px solid $border-white;
        }
        .product-page-filter {
          width: 100%;
          border-right: 1px solid $border-white;
          border-left: 1px solid $border-white;
        }
      }
    }
  }


  .blog-detail-page {
    .blog-detail {
      p {
        line-height: 1.5;
      }
    }
    .blog-contact {
      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .about-page {
    p {
      line-height: 1.2;
    }
  }
  .login-page {
    .theme-card {
      padding: 18px;
    }
  }
  .search-block {
    .btn-solid {
      padding: 10px 5px;
    }
    .form-header {
      .input-group {
        input {
          padding: 10px 12px;
        }
      }
    }
  }
  .contact-page {
    .map {
      iframe {
        height: 200px;
      }
    }
  }
  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 15px 13px;
          }
        }
      }
      .product-search-count-bottom {
        padding-left: 10px;
        h5 {
          line-height: 15px;
        }
      }
    }
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 80%;
        }
      }
    }
  }
  .cart-section, .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 115px;
          .mobile-cart-content {
            .col-xs-3 {
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          a {
            img {
              height: 80px;
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding-right: 27px;
          h2 {
            font-size: 21px;
          }
        }
      }
    }
  }
  .wishlist-section {
    .wishlist-buttons {
      &:last-child {
        a {
          margin-left: 0;
        }
      }
    }
  }
  .product-buttons {
    .btn-default {
      padding: 7px 16px;
    }
  }
  .product-description {
    .qty-box {
      .input-group {
        width: 100%;
      }
    }
  }
  .single-product-tables{
    &.detail-section{
      table {
        width: 70%;
      }
    }
  }
  .product-right {
    .timer {
      padding-left: 25px;
      span {
        .padding-l {
          padding-left: 10px;
        }
      }
    }
  }
  .product-form-box {
    .timer {
      span {
        width: 47px;
        .padding-l {
          padding-left: 9px;
        }
      }
    }
    .product-buttons {
      .btn-default {
        padding: 5px 5px;
      }
    }
  }
  .product-form-box, .border-product {
    .timer {
      span {
        width: 45px;
        .padding-l {
          padding-right: 5px;
        }
      }
    }
  }
  .portfolio-metro{
    h1{
      font-size: 20px;
    }
  }

  .breadcrumb-section-main {
    &.inner-2 {
      .breadcrumb-contain {
        ul {
          li {
            &:last-child {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .m-l-24 {
    margin-left: 10px;
  }
  .agency {
    &.agency-content {
      .agency-header-center-container {
        .agency-head {
          .agency-head-text {
            margin-top: -10px;
          }
        }
      }
    }
  }
  .saas1 {
    &.testimonial {
      .testi-profile {
        img {
          padding-left: 0;
        }
      }
    }
  }


  .collection-banner {
    .contain-banner {
      &.banner-3 {
        padding-left: 25px;
        padding-right: 25px;
        h2 {
          font-size:15px;
        }
      }
      &.banner-4 {
        padding-left: 35px;
        padding-right: 35px;
        h2 {
          font-size:15px;
        }
      }
    }
  }
}

/**=====================
       63. Responsive CSS end
  ==========================**/
