@import 'theme/variable' ;
$primary: $black;
$secondary: $black;
$dark: $black;
$light:#252525;
$font-awesome: FontAwesome;
@import 'theme/style';
@import 'theme/theme-dark';
@import 'theme/responsive';

